import { arrayShuffle } from "@utils/array";

import Answer from "./answer";
import { defaultQuizLocales, QuizLocales } from "./locales";

import Group, { IGroup } from "./group";
import Question, { IQuestion } from "./question";
import QuestionSingleSelect from "./question/singleselect";
import QuestionMultiSelect from "./question/multiselect";
import QuestionTrueOrFalse from "./question/trueorfalse";
import QuestionSequence from "./question/sequence";
import QuestionInput from "./question/input";
import QuestionMissingWords from "./question/missingwords";

export type QuizData = {
    id: number;
    title: string;
    time: number;
    questions: IQuestion[];
    groups: IGroup[];
}

type QuizSettings = {
    locales?: QuizLocales;
    onFinished?: (score: number, percentage: number) => void;
}

export type CreateEditorProps = {
    parent: HTMLElement;
    onSave: (data: QuizData) => Promise<boolean>;
    onAssetUpload: (data: string) => Promise<string>;
    onAssetDelete: (name: string) => Promise<boolean>;
    formatAssetUrl: (name: string) => string;
};

export type RenderProps = Pick<CreateEditorProps, "parent" | "formatAssetUrl"> & {
    previewMode?: boolean
};

export class Quiz {
    public id: number;
    public title: string;
    public time: number;
    public maxQuestions: number;
    public questions: Question[] = [];
    public groups: Group[] = [];
    private originalQuizData: QuizData;

    private locales: QuizLocales = defaultQuizLocales;
    private onFinished: QuizSettings["onFinished"];
    protected renderProps: RenderProps;
    protected isEditor: boolean = false;
    
    public currentIndex: number = 0;
    public currentView: "game" | "results" | "solutions" = "game";
    protected selectedGroupIndex: number = 0;
    private timerInterval;

    constructor(data: QuizData, settings?: QuizSettings) {
        this.id    = data.id;
        this.title = data.title;
        this.time  = data.time;
        
        if (data.groups.length === 0) {
            data.groups.push(
                new Group({
                    name: "Default",
                    maxQuestions: 0
                })
            );
        }

        data.groups.forEach(data => {
            let group = new Group(data);
            this.groups.push(group);
        });

        if (settings?.locales) {
            this.locales = {
                ...this.locales,
                ...settings.locales
            };
        }

        if (settings?.onFinished) {
            this.onFinished = settings.onFinished;
        }

        this.originalQuizData = data;
    }

    protected toArray(): QuizData
    {
        return {
            id:        this.id,
            title:     this.title,
            time:      this.time,
            questions: this.questions.map(q => q.toArray()),
            groups:    this.groups.map(g => g.toArray())
        };
    }


    public createQuestion(data: IQuestion): Question | null
    {
        switch(data.type) {
            case "singleselect":
                return new QuestionSingleSelect(data);
            case "multiselect":
                return new QuestionMultiSelect(data);
            case "trueorfalse":
                return new QuestionTrueOrFalse(data);
            case "sequence":
                return new QuestionSequence(data);
            case "input":
                return new QuestionInput(data);
            case "missingwords":
                return new QuestionMissingWords(data);
        }
    }

    protected initQuestions()
    {
        this.questions = [];

        let questionsData = structuredClone(this.originalQuizData.questions);
        
        if (! this.isEditor) {
            arrayShuffle(questionsData); // a groupon belüli random választáshoz

            let filteredQuestions: {
                [groupIndex: number]: IQuestion[]
            } = questionsData
                .reduce(
                    (obj, q) => {
                        if (! obj[q.groupIndex]) {
                            obj[q.groupIndex] = [];
                        }

                        let group = this.originalQuizData.groups[q.groupIndex];

                        if (group.maxQuestions === 0 || obj[q.groupIndex].length < group.maxQuestions) {
                            obj[q.groupIndex].push(q);
                        }

                        return obj;
                    },
                    {}
                );

            questionsData = Object.values(filteredQuestions)
                .reduce(
                    (arr, list) => arr.concat(list),
                    []
                );

            arrayShuffle(questionsData); // a végső sorrend randomizálása
        }

        for (let q of questionsData) {
            if (! this.isEditor) {
                arrayShuffle(q.answers);
            }

            let question = this.createQuestion(q);

            if (question) {
                this.questions.push(question);

            } else {
                console.error(`Undefined quiz question type! (${q.type})`);
            }
        }

        this.time = this.originalQuizData.time;
    }

    public render(props: RenderProps)
    {
        this.renderProps = props;
        this.initQuestions();

        const generate = () => {
            let parent = $(`
                <div>
                    <div class="header">
                        <div class="icon"></div>
                        
                        <div class="title">
                            ${this.title}
                        </div>

                        ${(this.time && this.time) > 0 ? `
                            <div class="timer">
                                ${this.time}
                            </div>
                        ` : ``}
                    </div>

                    <div class="content">
                        
                    </div>
                </div>
            `);

            let content = parent.find(".content");

            /*
             * QUESTIONS
             */
            if (this.currentView === "game" || this.currentView === "solutions") {
                let currentQuestion = (this.questions.length >= this.currentIndex+1)
                    ? this.questions[this.currentIndex]
                    : null;

                if (currentQuestion) {
                    currentQuestion
                        .render(this, {
                            ...props,
                            triggerUpdate
                        })
                        .appendTo(content);
                }
            }

            /*
             * RESULTS PAGE
             */
            if (this.currentView === "results") {
                let score      = this.getScore();
                let maxScore   = this.getMaxScore();
                let percentage = Math.round(score / maxScore * 100);
                let place      = this.getPlaceByPercentage(percentage);
    
                content.html(`
                    <div class="results">
                        <h1>${ percentage }%</h1>
                        <span>${ this.t(`place${place}`) }</span>
                        <h2>${score} / ${maxScore}</h2>
                    </div>
                `);
            }
            

            /*
             * FOOTER
             */
            let footer = $(`
                <div class="footer">
                    <div class="info-wrapper">
                        ${this.currentView !== "results"
                            ? `<span>${this.currentIndex+1} / ${this.questions.length}</span>`
                            : ``
                        }
                    </div>

                    <div class="buttons-wrapper">

                    </div>
                </div>
            `)
            .appendTo(parent);

            let buttonsHolder = footer.find(".buttons-wrapper");

            if (this.currentView !== "results") {
                // PREVIOUS
                if (this.currentIndex > 0) {
                    $(`
                        <div class="button outlined" quiz-button="prev">
                            <div class="icon-button big arrow-left"></div>
                        </div>
                    `)
                    .on("click", e => {
                        this.currentIndex = Math.max(0, this.currentIndex - 1);
                        triggerUpdate();
                    })
                    .appendTo(buttonsHolder);
                }

                // NEXT
                if (this.currentIndex <  this.questions.length - 1) {
                    $(`
                        <div class="button" quiz-button="next">
                            <div class="icon-button big arrow-right"></div>
                        </div>
                    `)
                    .on("click", e => {
                        this.currentIndex = Math.min(this.questions.length - 1, this.currentIndex + 1);
                        triggerUpdate();
                    })
                    .appendTo(buttonsHolder);
                }
            }

            if (this.currentView === "results") {
                // VIEW SOLUTION
                $(`
                    <div class="button outlined" quiz-button="solution">
                        <div class="icon-button big eye"></div>
                    </div>
                `)
                .on("click", e => {
                    this.viewSolution();
                    triggerUpdate();
                })
                .appendTo( footer.find(".buttons-wrapper") );
            }

            if (this.currentView === "results" || this.currentView === "solutions") {
                // RESTART GAME
                $(`
                    <div class="button" quiz-button="restart">
                        <div class="icon-button big rotate"></div>
                    </div>
                `)
                .on("click", e => {
                    this.restartGame();
                    triggerUpdate();

                    this.startTimer(props, triggerUpdate);
                })
                .appendTo( footer.find(".buttons-wrapper") );
            }
                

            if (this.currentView === "game") {
                // FINISH GAME
                if (this.currentIndex === this.questions.length - 1) {
                    $(`
                        <div class="button" quiz-button="finish">
                            <div class="icon-button big circle-check"></div>
                        </div>
                    `)
                    .on("click", e => {
                        this.finishGame();
                        triggerUpdate();
                    })
                    .appendTo(buttonsHolder);
                }
            }

            return parent;
        }


        const triggerUpdate = () => {
            let content = generate();
            
            $(props.parent)
                .addClass("mve-quiz")
                .empty()
                .append(content);
        }


        // initial render
        triggerUpdate();

        this.startTimer(props, triggerUpdate);
    }

    private finishGame()
    {
        this.currentView = "results";
        this.stopTimer();

        let score      = this.getScore();
        let maxScore   = this.getMaxScore();
        let percentage = Math.round(score / maxScore * 100);

        this?.onFinished?.(score, percentage);
    }

    private viewSolution(): void
    {
        this.currentView = "solutions";
        this.currentIndex = 0;
    }

    private restartGame(): void
    {
        this.currentView = "game";
        this.currentIndex = 0;
        this.initQuestions();
    }

    private startTimer(props: RenderProps, triggerUpdate: Function): void
    {
        if (props.previewMode || this.time <= 0)
            return;

        this.timerInterval = setInterval(() => {
            this.time -= 1;

            $(parent).find(".header .timer")
                .text(this.time);

            if (this.time <= 0) {
                this.finishGame();
                triggerUpdate();
            }
        }, 1000);
    }

    private stopTimer(): void
    {
        this.time = 0;
        clearInterval(this.timerInterval);
    }

    public t(key: string): string
    {
        return this.locales[key] || "n/a";
    }

    private getPlaceByPercentage(percentage: number): number
    {
        if (percentage >= 80) {
            return 5;
        } else if (percentage >= 60) {
            return 4;
        } else if (percentage >= 40) {
            return 3;
        } else if (percentage >= 20) {
            return 2;
        } else  {
            return 1;
        }
    }

    public getScore(): number
    {
        return this.questions.reduce(
            (score, q) => score + q.getScore(),
            0
        );
    }

    public getMaxScore(): number
    {
        return this.questions.reduce(
            (score, question) => score + question.getMaxScore(),
            0
        );
    }

    public destroy() {
        this.renderProps?.parent?.remove();
    }
}


export class QuizEditor extends Quiz {
    protected declare renderProps: CreateEditorProps;
    protected isEditor: boolean = true;

    constructor(data: QuizData, settings?: QuizSettings) {
        super(data, settings);
    }

    public render(props: CreateEditorProps)
    {
        this.renderProps = props;
        this.initQuestions();

        const generate = () => {
            let parent = $("<div />");

            /*
             * TITLE
             */
            let title = $(`
                <div class="property-group">
                    <span>Title</span>
                    <input type="text" />
                </div>
            `)
            .appendTo(parent);

            title.find("input")
                .val(this.title)
                .on("change", e => {
                    this.title = e.currentTarget.value;
                });


            /*
             * TIME
             */
            let time = $(`
                <div class="property-group">
                    <span>Time limit (sec)</span>
                    <input type="number" placeholder="unlimited" />
                </div>
            `)
            .appendTo(parent);

            time.find("input")
                .val(this.time > 0 ? this.time : null)
                .on("change", e => {
                    this.time = parseInt(e.currentTarget.value) || 0;
                });
            

            /*
             * GROUPS
             */
            let groupsHolder = $(`
                <div class="group-tabs">
                    <div class="tab tab-placeholder"></div>
                    <div button="new-group" class="tab" style="margin-left: auto"></div>
                </div>
            `)
            .on("click", "[button='new-group']", e => {
                let group = new Group({
                    name: "New group",
                    maxQuestions: 0
                });

                this.groups.push(group);
                triggerUpdate();
                
            })
            .appendTo(parent);
            
            for (let i in this.groups) {
                let group = this.groups[i];

                $(`
                    <div tab-index="${i}" class="tab">
                        ${group.name} #${i}
                    </div>
                `)
                .on("click", e => {
                    this.selectedGroupIndex = parseInt(i);
                    triggerUpdate();
                })
                .toggleClass("active", parseInt(i) == this.selectedGroupIndex)
                .insertBefore(groupsHolder.find(".tab-placeholder"));
            }


            /*
             * GROUP SETTINGS
             */
            let selectedGroup = this.groups[this.selectedGroupIndex];
            
            selectedGroup.renderSettings()
                .appendTo(parent);


            /*
             * QUESTIONS
             */
            for (let question of this.questions) {
                if (question.groupIndex === this.selectedGroupIndex) {
                    question.createEditorFields(this, {
                        ...props,
                        triggerUpdate,
                        deleteQuestion: (q: Question) => {
                            this.questions = this.questions.filter(v => v !== q);
                            triggerUpdate();
                        },
                        deleteAnswer: (q: Question, a: Answer) => {
                            q.answers = q.answers.filter(v => v !== a);
                            triggerUpdate();
                        }
                    })
                    .appendTo(parent);
                }
            }


            /*
             * FOOTER
             */
            let footer = $(`
                <div class="quiz-footer">
                    <div class="info-wrapper">
                    
                    </div>

                    <div class="buttons-wrapper">
                        <div button="save" class="button">
                            Save
                        </div>
                    </div>
                </div>
            `)
            .on("click", "[button=save]", e => {
                props.onSave?.( this.toArray() );
            })
            .appendTo(parent);

            ["singleselect", "multiselect", "trueorfalse", "sequence", "input", "missingwords"]
                .forEach(type => {
                    $(`
                        <div class="icon-button add">
                            Add question (${type})
                        </div>
                    `)
                    .on("click", e => {
                        let question = this.createQuestion({
                            type: type as any,
                            text: "New question",
                            answers: [],
                            groupIndex: this.selectedGroupIndex
                        });
                        question.addNewAnswer();
        
                        this.questions.push(question);
        
                        triggerUpdate();
                    })
                    .appendTo(footer.find(".info-wrapper"))
                });
            
            return parent;
        }


        const triggerUpdate = () => {
            let content = generate();
            
            $(props.parent)
                .addClass("mve-quiz-editor")
                .empty()
                .append(content);
        }


        // initial render
        triggerUpdate();
    }
}
