const normalize = (arr: string[]): string => {
    arr = arr.filter(part => typeof part === "string" && part !== "");
    
    if (arr.length === 0)
        return "";

    let result: string[] = [];

    // If the first part is a plain protocol, we combine it with the next part.
    if (arr[0].match(/^[^/:]+:\/*$/) && arr.length > 1) {
        arr[0] = arr.shift() + arr[0];
    }

    // If the first part is a leading slash, we combine it with the next part.
    if (arr[0] === "/" && arr.length > 1) {
        arr[0] = arr.shift() + arr[0];
    }

    // There must be two or three slashes in the file protocol, two slashes in anything else.
    if (arr[0].match(/^file:\/\/\//)) {
        arr[0] = arr[0].replace(/^([^/:]+):\/*/, "$1:///");

    // If the first part is not an IPv6 host, we replace the protocol.
    } else if (!arr[0].match(/^\[.*:.*\]/)) {
        arr[0] = arr[0].replace(/^([^/:]+):\/*/, "$1://");
    }

    for (let i = 0; i < arr.length; i++) {
        let component: string = arr[i];

        // Removing the starting slashes for each component but the first.
        if (i > 0) {
            component = component.replace(/^[\/]+/, "");
        }

        // Removing the ending slashes for each component but the last.
        if (i < arr.length - 1) {
            component = component.replace(/[\/]+$/, "");
        
        // For the last component we will combine multiple slashes to a single one.
        } else {
            component = component.replace(/[\/]+$/, "/");
        }

        if (component === "")
            continue;

        result.push(component);
    }

    let str = "";

    for (let i = 0; i < result.length; i++) {
        const part = result[i];

        // Do not add a slash if this is the first part.
        if (i === 0) {
            str += part;
            continue;
        }

        const prevPart = result[i - 1]

        // Do not add a slash if the previous part ends with start of the query param or hash.
        if (prevPart && prevPart.endsWith("?") || prevPart.endsWith("#")) {
            str += part;
            continue;
        }

        str += "/" + part;
    }

    // Each input component is now separated by a single slash except the possible first plain protocol part.

    // remove trailing slash before parameters or hash
    str = str.replace(/\/(\?|&|#[^!])/g, "$1");

    // replace ? and & in parameters with &
    const [beforeHash, afterHash] = str.split("#");
    const parts = beforeHash.split(/(?:\?|&)+/).filter(Boolean);
    str = parts.shift() + (parts.length > 0 ? "?" : "") + parts.join("&") + (afterHash && afterHash.length > 0 ? "#" + afterHash : "");
    
    return str;
}

export const joinUrl = (...args) => {
    const parts = Array.from(Array.isArray(args[0]) ? args[0] : args);

    return normalize(parts);
}
