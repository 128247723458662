export type QuizLocales = {
    place1?: string;
    place2?: string;
    place3?: string;
    place4?: string;
    place5?: string;
    answer?: string;
    correct_answer_is?: string;
}

export const defaultQuizLocales: QuizLocales = {
    place1: "Very poor result.",
    place2: "Pay much more attention.",
    place3: "Practise a bit more.",
    place4: "Well done!",
    place5: "Excellent!",
    answer: "Answer...",
    correct_answer_is: "Correct answer:"
};
