import React, { useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import classNames from "classnames";

type Props = React.InputHTMLAttributes<HTMLInputElement>;


const NumberInput = ({ className, ...args }: Props) => {
    const ref = useRef<HTMLInputElement>();
    const [isMouseOver, setMouseOver] = useState(false);
    const [isCtrlDown, setCtrlDown] = useState(false);
    const [isMoving, setMoving] = useState(false);

    useEventListener("keydown", async ({ ctrlKey, metaKey }: KeyboardEvent) => {
        let isCtrlOrCmd = ctrlKey || metaKey;
        setCtrlDown(isCtrlOrCmd);
    })
    
    useEventListener("keyup", async ({ ctrlKey, metaKey }: KeyboardEvent) => {
        let isCtrlOrCmd = ctrlKey || metaKey;
        setCtrlDown(isCtrlOrCmd);

        if (! isCtrlOrCmd) {
            setMoving(false);
        }
    })

    useEventListener("mousedown", async ({ button, ctrlKey, metaKey }: MouseEvent) => {
        let isCtrlOrCmd = ctrlKey || metaKey;

        if (button === 0 && isCtrlOrCmd && isMouseOver) {
            setMoving(true);
        }
    })

    useEventListener("mousemove", async ({ movementX }: MouseEvent) => {
        if (isMoving && ref.current) {
            let step = parseFloat(args.step as any) || 0.5;
            let min  = parseFloat(args.min as any);
            let max  = parseFloat(args.max as any);
            
            let newValue = ref.current.valueAsNumber + (movementX * step * 0.4);
            
            if (typeof min === "number" && !isNaN(min)) {
                newValue = Math.max(min, newValue);
            }
            if (typeof max === "number" && !isNaN(max)) {
                newValue = Math.min(max, newValue);
            }
            
            const event = new Event("input", { bubbles: true });
            ref.current.value = newValue.toFixed(2);
            ref.current.dispatchEvent(event);
        }
    })

    useEventListener("mouseup", async () => {
        setMoving(false);
    })

    useEffect(() => {
        if (isMoving) {
            ref.current.requestPointerLock();
            
        } else {
            document.exitPointerLock();
        }
    }, [ isMoving ]);

    return (
        <input
            ref={ref}
            type="number"
            className={classNames(className, { "slide-editing-preview": isMoving || (isMouseOver && isCtrlDown) })}
            onMouseOver={_ => setMouseOver(true)}
            onMouseLeave={_ => setMouseOver(false)}
            {...args}
        />
    );
}

export default NumberInput;
