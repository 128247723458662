interface IHandlers {
    [key: string]: {
        callback: Function,
        args: any[]
    }
}

let handlers: IHandlers = {};
let activeFrameID: number = null;


const onFrame = () => {
    let count = 0;

    for (let name in handlers) {
        let { callback, args } = handlers[name];

        callback(...args);
        count++;
    }
    
    if (count > 0) {
        activeFrameID = requestAnimationFrame(onFrame);
    }
}

const request = (name: string, callback: Function, args: any[] = []) => {
    if (handlers[name]) {
        console.error(`FrameService: handler with name '${name}' already exists!`);
        return false;
    }

    handlers[name] = { callback, args };
    
    if (! activeFrameID) {
        activeFrameID = requestAnimationFrame(onFrame);
    }

    return true;
}

const cancel = (name: string) => {
    delete handlers[name];

    if (activeFrameID) {
        cancelAnimationFrame(activeFrameID);
        activeFrameID = null;
    }
}


export default { request, cancel };
