import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";

import NumberInput from "@components/inputs/number";


export default class Rotation extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.rotation";

    public value: number = 0;

    
    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: number) {
        value = value % 360;

        if (value < 0)
            value = 360 + value;

        value = Number(value.toFixed(2));

        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }
    
    public toStyle() {
        return {
            transform: `rotate(${this.value}deg)`
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }
    
    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);

        return (
            <div>
                <NumberInput
                    defaultValue={value}
                    min={0}
                    max={360}
                    onInput={e => setValue(e.currentTarget.valueAsNumber)}
                />
            </div>
        );
    }
}
