import { Quiz } from "../quiz";
import Question, { CreateEditorFieldsProps, IQuestion } from "../question";

export default class QuestionInput extends Question {
    constructor(props: IQuestion) {
        super(props);

        this.answers[0]?.setValue("");
    }

    protected createAnswerFields(quiz: Quiz, props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerFields(quiz, props);

        let answersCont = $(`
            <div class="answers"></div>
        `)
        .appendTo(parent);

        let firstAnswer = this.answers[0];

        if (firstAnswer) {
            let elem = $(`
                <div answer-index="0" class="answer-group">
                    <div style="flex: 1; display: flex; flex-direction: column; gap: 5px">
                        <input type="text" placeholder="${ quiz.t("answer") }" value="${ firstAnswer.value }" />
                    </div>
                </div>
            `)
            .appendTo(answersCont);
            
            if (quiz.currentView === "game") {
                // text
                elem.on("change", "input", e => {
                    firstAnswer.setValue(e.currentTarget.value);
                });

            }  else {
                elem.find("input")
                    .prop("disabled", true);
            }
        }

        return parent;
    }

    protected createAnswerEditorFields(props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerEditorFields(props);

        $(`
            <div>
                <div class="property-group">
                    <span>Answers</span>
                </div>

                <div class="answers">
                    
                </div>
            </div>
        `)
        .appendTo(parent);

        let answersCont = parent.find(".answers");

        for (let i in this.answers) {
            let answer = this.answers[i];

            let elem = $(`
                <div class="answer-group">
                    <input type="text" placeholder="Answer..." />
                </div>
            `)
            // text
            .on("change", "input", e => {
                answer.text         = e.currentTarget.value;
                answer.correctValue = e.currentTarget.value;
            })
            .appendTo(answersCont);

            // delete
            $(`<div class="icon-button big trash"></div>`)
                .on("click", e => {
                    props.deleteAnswer(this, answer);
                })
                .toggle(this.answers.length > this.minAnswers)
                .appendTo(elem);

            // defaults
            elem.find("input").val(answer.text);
        }

        return parent;
    }

    public highlightSolutions(parent: JQuery<HTMLElement>)
    {
        let isCorrect = this.isAnswerCorrect();
        let type = isCorrect
            ? "correct"
            : "incorrect";

        let group = parent.find(`.answers .answer-group[answer-index="0"]`)
            .addClass(type);

        if (! isCorrect) {
            let firstAnswer = this.answers[0];
    
            $(`<div>Correct answer: ${ firstAnswer.correctValue }</div>`)
                .insertAfter( group.find("input") );
        }
    }

    public isAnswered(): boolean
    {
        let firstAnswer = this.answers[0];
        
        return typeof firstAnswer.value === "string" && firstAnswer.value.length > 0;
    }

    // NOTE
    // mindig csak a legelső input jelenik meg a usernek és az tárolja a beírt válaszát is
    // a továbbiak csak alternatív válaszlehetőségek amelyeket elfogadunk még helyes válaszként
    public isAnswerCorrect(): boolean
    {
        let firstAnswer = this.answers[0];

        if (typeof firstAnswer.value !== "string")
            return false;

        for (let answer of this.answers) {
            if (typeof answer.correctValue === "string" && answer.correctValue.toLowerCase() === firstAnswer.value.toLowerCase() ) {
                return true;
            }
        }

        return false;
    }

    public getMaxScore(): number
    {
        if (typeof this.score === "number")
            return this.score;

        return 1;
    }

    public getScore(): number
    {
        return this.isAnswerCorrect()
            ? this.getMaxScore()
            : 0;
    }
}
