import React from "react";
import {
    Menu as MenuComponent,
    ControlledMenu as ControlledMenuComponent,
    ControlledMenuProps as ControlledMenuComponentProps,
    MenuItem,
    MenuButton,
    MenuHeader,
    MenuAlign,
    MenuDivider,
    SubMenu,
    MenuDirection,
    FocusableItem
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface MenuData {
    type?: "checkbox" | "radio";
    subMenu?: MenuData[];
    title?: string;
    icon?: IconDefinition;
    hidden?: boolean;
    header?: boolean;
    divider?: boolean;
    checked?: boolean;
    focusable?: boolean;
    component?: JSX.Element;
    isDisabled?: () => boolean;
    onClick?: () => void;
}

interface MenuProps {
    data?: MenuData[];
    title?: string;
    component?: JSX.Element;
    align?: MenuAlign;
    direction?: MenuDirection;
    portal?: boolean;
}
interface ControlledMenuProps {
    data: MenuData[];
    anchorPoint: ControlledMenuComponentProps["anchorPoint"];
    opened: boolean;
    onClose: ControlledMenuComponentProps["onClose"];
}


const Menu = ({ data = [], component, title, portal = true, ...props }: MenuProps) => {
    return (
        <MenuComponent
            menuButton={
                component || <MenuButton>{ title || "Menu" }</MenuButton>
            }
            theming="dark"
            menuStyle={{ zIndex: 9999 }}
            portal={portal}
            arrow={true}
            boundingBoxPadding="0.35rem"
            transition
            {...props}
        >
            { MenuItemsRenderer(data) }
        </MenuComponent>
    );
}

export default Menu;


const ControlledMenu = ({ data, opened, ...props }: ControlledMenuProps) => {
    return (
        <ControlledMenuComponent
            theming="dark"
            menuStyle={{ zIndex: 9999 }}
            portal={true}
            direction="right"
            state={opened ? "open" : "closed"}
            {...props}
        >
            { MenuItemsRenderer(data) }
        </ControlledMenuComponent>
    );
}

export { ControlledMenu };


const MenuItemsRenderer = (data: MenuData[]) => {
    return data.map((item: MenuData, i: number) => {
        const label = (
            <>
                { item.icon && <FontAwesomeIcon icon={item.icon} className="me-2" style={{ fontSize: "110%" }} /> }
                { item.title }
            </>
        );

        let elem;
        let isDisabled = item?.isDisabled?.() ?? false;

        if (item.header) {
            return <MenuHeader key={i}>{ item.title }</MenuHeader>;
        }

        if (item.divider) {
            return <MenuDivider key={i} />;
        }

        if (item.subMenu) {
            return (
                <SubMenu key={i} label={label} disabled={isDisabled}>
                    { MenuItemsRenderer(item.subMenu) }
                </SubMenu>
            );
        }

        if (item.component) {
            elem = (
                <div key={i}>
                    { item.component }
                </div>
            );

        } else {
            elem = (
                <MenuItem
                    key={i}
                    type={item.type ?? null}
                    onClick={item.onClick}
                    disabled={isDisabled}
                    hidden={item.hidden ?? false}
                    checked={item.checked ?? false}
                >
                    { label }
                </MenuItem>
            );
        }

        if (item.focusable) {
            return (
                <FocusableItem key={i}>
                    { elem }
                </FocusableItem>
            );
        }

        return elem;
    });
}
