const config = {
    APP_BASE_URL: process.env.APP_URL,
    API_BASE_URL: process.env.API_URL,

    CLIP_IMAGE_ACCEPTED_EXTENTIONS: [ ".png", ".jpg", ".jpeg", ".svg" ],

    VIDEO_ACCEPTED_EXTENSIONS: [ ".mp4" ],

    FONTS: [
        { name: "Roboto",    type: "google" },
        { name: "Open Sans", type: "google" },
        { name: "Oswald",    type: "google" },
        { name: "Inter",     type: "google" }
    ],

    MOZAWEB_LANGUAGES: [
        "en", "en_US", "hu",
        "sq", "ar", "ar_SA", "hy", "az", "be", "bn", "bs", "bg", "zh", "zh_HK", "zh_TW",
        "hr", "cs", "da", "nl", "et", "fo", "fi", "fr", "ka", "de", "el", "he",
        "hi", "id", "it", "ja", "kk", "ko", "ky", "la", "lv", "lt", "mk", "mr", "mn",
        "no", "fa", "pl", "pt", "pt_BR", "ro", "ru", "sr", "sk", "sl", "es", "es_CL",
        "sv", "th", "tr", "tk", "uk", "uz", "vi"
    ]
}

export default config;
