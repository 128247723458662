import React from "react";

import { angleBetweenPoints, distanceBetweenPoints } from "@utils/trigonometry";
import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import AnchorPosition, { getAnchorCssStyle, getAnchorOffsettedValues, inverseAnchorPosition } from "@enums/anchorposition";
import AnimPriorityGroup from "@enums/animprioritygroup";

import { getSubtitleManager } from "@providers/subtitlemanager";
import ClipEffect, { AnimPriority } from "@models/clipeffect";
import TextClip from "@models/clip/textclip";
import ImageClip from "@models/clip/imageclip";

import TitleEffect from "./title";

import Position from "@clip/properties/position";
import Color from "@clip/properties/color";
import Range from "@clip/properties/range";
import Anchor from "@clip/properties/anchor";


export default class TargetLineEffect extends ClipEffect {
    public type = "TargetLineEffect";
    public declare clip: TextClip | ImageClip;
    public priority: AnimPriority = [AnimPriorityGroup.Low, 0];

    @Set({ i18nKey: "pages.project.property_name.target_position" })
    @OnChange((effect: TargetLineEffect) => {
        effect.updateLine();
    })
    public position: Position = new Position(this);

    @Set({ i18nKey: "pages.project.property_name.thickness", value: 7, min: 5, max: 15 })
    @OnChange((effect: TargetLineEffect) => {
        effect.create();
    })
    public thickness: Range = new Range(this);

    @Set({ i18nKey: "pages.project.property_name.line_color" })
    @OnChange((effect: TargetLineEffect) => {
        effect.elements.dot2.css({
            background: effect.color.value
        });
        effect.update();
    })
    public color: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.anchor" })
    @OnChange((effect: TargetLineEffect) => {
        effect.updateLine();
    })
    public anchor: Anchor = new Anchor(this);


    constructor() {
        super();

        this.position.x      = 50;
        this.position.y      = 50;
        this.thickness.value = 2;
        this.color.value     = "#ED7238";
        this.anchor.value    = AnchorPosition.CenterCenter;
    }

    public init() {
        super.init();
        
        OnChangeDecorator.subscribe(this.clip, [], () => {
            this.update();
            this.updateLine();
        });
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.position  = this.position.exportPayload();
        payload.data.thickness = this.thickness.exportPayload();
        payload.data.color     = this.color.exportPayload();
        payload.data.anchor    = this.anchor.exportPayload();

        return payload;
    }


    public getPropertyEditorComponents() {
        const hasTitleEffect = this.clip.hasEffect("TitleEffect");

        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.position.getPropertyEditorComponent() }
                { this.thickness.getPropertyEditorComponent() }
                { this.color.getPropertyEditorComponent() }
                { !hasTitleEffect && this.anchor.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const wrapper      = subtitleElement.find(".subtitle-wrapper");
        const titleEffect  = this.clip.getEffect<TitleEffect>("TitleEffect");

        const isLeftSided  = (titleEffect && titleEffect.anchor.value === AnchorPosition.LeftCenter) ?? true;

        let [ manager ]    = getSubtitleManager();
        let videoWidth     = manager.subtitlesHolderElement.width();

        let line1Width = "2.5rem";
        let thickness  = `${this.thickness.value * (videoWidth / 1920)}px`;

        if (titleEffect) {
            // a kis vonal
            this.elements.line1 = $("<div/>")
                .css({
                    position:   "absolute",
                    width:      line1Width,
                    height:     thickness,
                    left:       isLeftSided ? "100%" : 0,
                    top:        "50%",
                    transform:  isLeftSided ? "translateY(-50%)" : "translateY(-50%) rotate(180deg)",
                    transformOrigin: "left center",
                    background: this.color.value
                })
                .prependTo(wrapper);

            // két vonal törésénél a pont
            this.elements.dot1 = $("<div/>")
                .css({
                    position:     "absolute",
                    right:        "0",
                    top:          "50%",
                    transform:    "translate(50%, -50%)",
                    borderRadius: "50%",
                    background:   this.color.value
                })
                .prependTo(this.elements.line1);
        }

        // a nagy vonal
        this.elements.line2 = $("<div/>")
            .css({
                position:        "absolute",
                height:          thickness,
                left:            isLeftSided ? `calc(100% + ${line1Width})` : `calc(0px - ${line1Width})`,
                top:             "50%",
                transform:       "translateY(-50%)",
                transformOrigin: "center left"
            })
            .prependTo(wrapper);

        // vonal végén a pont
        this.elements.dot2 = $("<div/>")
            .css({
                position:     "absolute",
                right:        "0",
                top:          "50%",
                transform:    "translate(50%, -50%)",
                borderRadius: "50%",
                background:   this.color.value
            })
            .prependTo(this.elements.line2);
        
        this.updateLine();
    }
    
    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        const titleEffect = this.clip.getEffect<TitleEffect>("TitleEffect");

        const startDelay        = titleEffect?.SHOW_DURATION || 0;
        const titleHideDuration = titleEffect?.HIDE_DURATION || 0;
        
        let values = {
            line1Progress: 0,
            line2Progress: 0,
            dotSize:       0
        };
        
        const update = () => {
            // line1
            this.elements?.line1?.css("background", `linear-gradient(90deg, ${this.color.value} ${values.line1Progress}%, transparent ${values.line1Progress}%)`);
        
            // line2
            let gradient = titleEffect
                    ? []
                    : [ "transparent 0%" ];

            if (values.line2Progress > 30)
                gradient.push(`${this.color.value} 30%`);

            gradient.push(`${this.color.value} ${values.line2Progress}%, transparent ${values.line2Progress}%`);
            
            this.elements.line2.css("background", `linear-gradient(90deg, ${gradient.join(", ")})`);

            let lineHeight = this.elements.line2.height();

            // dot2
            this.elements.dot2.css({
                width:  `calc(${lineHeight}px * 1.75 * ${values.dotSize})`,
                height: `calc(${lineHeight}px * 1.75 * ${values.dotSize})`
            });

            // dot1
            this.elements?.dot1?.css({
                display: values.line1Progress === 100 ? "block" : "none",
                width:  `${lineHeight}px`,
                height: `${lineHeight}px`
            });
        }

        // show
        if (titleEffect) {
            this.timeline
                .add({
                    targets:       values,
                    easing:        "linear",
                    duration:      200,
                    delay:         startDelay,
                    line1Progress: 100,
                    update:        update
                });
        }

        this.timeline
            .add({
                targets:       values,
                easing:        "easeInQuad",
                duration:      500,
                line2Progress: 100,
                update:        update
            })
            .add({
                targets:  values,
                easing:   "linear",
                duration: 200,
                dotSize:  1,
                update:   update
            });

        // hide
        const HIDE_DELAY = this.clip.duration - titleHideDuration - 700 - (titleEffect ? (startDelay + 200) : 0);

        this.timeline
            .add({
                targets:  values,
                easing:   "linear",
                duration: 200,
                delay:    HIDE_DELAY,
                dotSize:  0,
                update:   update
            })
            .add({
                targets:       values,
                easing:        "easeInQuad",
                duration:      500,
                line2Progress: 0,
                update:        update
            });

        if (titleEffect) {
            this.timeline
                .add({
                    targets:       values,
                    easing:        "linear",
                    duration:      200,
                    line1Progress: 0,
                    update:        update
                });
        }
    }

    private updateLine() {
        const titleEffect = this.clip.getEffect<TitleEffect>("TitleEffect");
        
        const subtitleElement = this.clip.findSubtitleElement();
        const wrapper         = subtitleElement.find(".subtitle-wrapper");

        const [ subtitleManager ] = getSubtitleManager();

        const [ contWidth, contHeight ] = subtitleManager.getDimensions();
        const parentRect  = subtitleManager.subtitlesHolderElement[0].getBoundingClientRect();
        const wrapperRect = wrapper[0].getBoundingClientRect();
        
        const anchor = titleEffect ? inverseAnchorPosition(titleEffect.anchor.value) : this.anchor.value;

        let [ startX, startY ] = getAnchorOffsettedValues(
            [ wrapperRect.left - parentRect.left,  wrapperRect.top - parentRect.top ],
            [ wrapperRect.width,                   wrapperRect.height               ],
            anchor
        );

        if (titleEffect) {
            startX += this.elements.line1.width() * (titleEffect.anchor.value === AnchorPosition.LeftCenter ? 1 : -1);
        }

        const targetX  = contWidth  * (this.position.x / 100);
        const targetY  = contHeight * (this.position.y / 100);

        const distance = distanceBetweenPoints(startX, startY, targetX, targetY);
        const angle    = angleBetweenPoints(startX, startY, targetX, targetY) - this.clip.rotation.value;

        /* át-matematikázzuk a distance px-jét, hogy az hány százalék
           a .subtitle-wrapper-en belül... hogy ne absolute px-ben legyen és
           böngésző ablak átméretezésekor ne csússzon el */
        const distancePercent = (distance / contWidth * 100) * (contWidth / wrapperRect.width);

        this.elements.line2.css({
            transform: `translateY(-50%) rotate(${angle}deg)`,
            width:     `${distancePercent}%`
        });

        if (! titleEffect) {
            this.elements.line2.css(
                getAnchorCssStyle(anchor, "position")
            );
        }
    }
}
