import React from "react";

import { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import ClipEffect from "@models/clipeffect";
import Color from "@clip/properties/color";
import Range from "@clip/properties/range";
import { getSubtitleManager } from "@providers/subtitlemanager";


export default class BackgroundEffect extends ClipEffect {
    public type = "BackgroundEffect";

    @OnChange((effect: BackgroundEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public backgroundColor: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.padding", max: 20 })
    @OnChange((effect: BackgroundEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public padding: Range = new Range(this);

    @Set({ i18nKey: "pages.project.property_name.border_radius", max: 20 })
    @OnChange((effect: BackgroundEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public borderRadius: Range = new Range(this);


    constructor() {
        super();
        
        this.backgroundColor.value = "#ED7238";
        this.padding.value         = 5;
        this.borderRadius.value    = 0;
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.backgroundColor = this.backgroundColor.exportPayload();
        payload.data.padding = this.padding.exportPayload();
        payload.data.borderRadius = this.borderRadius.exportPayload();

        return payload;
    }
    
    
    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.backgroundColor.getPropertyEditorComponent() }
                { this.padding.getPropertyEditorComponent() }
                { this.borderRadius.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const [ subtitleManager ] = getSubtitleManager();

        let scaleRatio   = subtitleManager.getSubtitleScaleRatio();
        let padding      = this.padding.value * scaleRatio;
        let borderRadius = this.borderRadius.value * scaleRatio;

        subtitleElement.find(".subtitle-content")
            .css({
                padding:         `${padding}px`,
                borderRadius:    `${borderRadius}px`,
                backgroundColor: this.backgroundColor.value
            });
    }
}
