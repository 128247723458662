import React from "react";

import { nbsp } from "@utils/string";

import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import PropertyNote from "@components/note/propertynote";

import ClipEffect from "@models/clipeffect";
import TextClip from "@models/clip/textclip";
import NumberProperty from "@clip/properties/number";
import { Trans } from "react-i18next";

const counterRegex = /{(\s|&nbsp;)*counter(\s|&nbsp;)*}/g;

export default class CounterEffect extends ClipEffect {
    public type = "CounterEffect";
    public declare clip: TextClip;

    @Set({ i18nKey: "pages.project.property_name.duration" })
    @OnChange((effect: CounterEffect) => {
        effect.create();
    })
    public duration: NumberProperty = new NumberProperty(this);

    @Set({ i18nKey: "pages.project.property_name.start" })
    @OnChange((effect: CounterEffect) => {
        effect.create();
    })
    public start: NumberProperty = new NumberProperty(this);

    @Set({ i18nKey: "pages.project.property_name.end" })
    @OnChange((effect: CounterEffect) => {
        effect.create();
    })
    public end: NumberProperty = new NumberProperty(this);


    constructor() {
        super();

        this.duration.value = 5000;
        this.start.value    = 1;
        this.end.value      = 10;
    }

    public init() {
        super.init();
        
        OnChangeDecorator.subscribe(this.clip, [], () => {
            this.update();
        });
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.duration = this.duration.exportPayload();
        payload.data.start    = this.start.exportPayload();
        payload.data.end      = this.end.exportPayload();

        return payload;
    }


    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <PropertyNote className="mt-2">
                    <Trans
                        i18nKey="pages.project.property_note.counter"
                        components={{
                            tag: <code>{ nbsp("{ counter }") }</code>
                        }}
                    />
                </PropertyNote>

                { this.duration.getPropertyEditorComponent() }
                { this.start.getPropertyEditorComponent() }
                { this.end.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        let values = {
            count: this.start.value
        };

        const update = () => {
            let count = Math.round(values.count);
            let text  = this.clip.getSubtitleHTML().replaceAll(counterRegex, count.toString());

            subtitleElement.find(".subtitle-content").html(text);
        }

        // defaults
        this.timeline
            .add({
                targets:  values,
                duration: 1,
                count:    this.start.value,
                update:   update
            });
        
        // show
        this.timeline
            .add({
                targets:  values,
                duration: this.duration.value,
                count:    this.end.value,
                update:   update
            });
    }
}
