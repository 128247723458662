import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipProperty from "@models/clipproperty";

import ClipPropertyChangeCommand from "@commands/clip_property_change";

import NumberInput from "@components/inputs/number";


export default class Position extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.position";
    public x: number = 0;    
    public y: number = 0;


    public exportPayload() {
        return {
            ...super.exportPayload(),
            x: this.x,
            y: this.y
        };
    }


    public set(x: number, y: number) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { x, y });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public toStyle() {
        return {
            left: this.x + "%",
            top:  this.y + "%"
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [x, setX] = useState(this.x);
        const [y, setY] = useState(this.y);
    
        useUpdateEffect(() => {
            this.set(x, y);
        }, [ x, y ]);

        return (
            <div>
                <div className="d-flex gap-2">
                    <span>x</span>
                    <NumberInput
                        defaultValue={x}
                        onInput={e => setX(e.currentTarget.valueAsNumber)}
                    />
                </div>
    
                <div className="d-flex mt-1 mb-2 gap-2">
                    <span>y</span>
                    <NumberInput
                        defaultValue={y}
                        onInput={e => setY(e.currentTarget.valueAsNumber)}
                    />
                </div>
            </div>
        );
    }
}
