import { parse as transformParse, stringify as transformStringify } from "transform-parser";

export const mergeStyles = (stylesList: object[]) => {
    let mergedStyles: any = {};

    for (let styles of stylesList) {
        for (let key in styles) {
            let value = styles[key as keyof typeof styles];

            if (key === "transform" && mergedStyles["transform"]) {
                let newTransform = transformStringify({
                    ...transformParse(mergedStyles["transform"]),
                    ...transformParse(value)
                });

                mergedStyles[key] = newTransform;
            
            } else {
                mergedStyles[key] = value;
            }
        }
    }

    return mergedStyles;
}


export const objToCss = (obj: any) => {
    let style: any = [];

    for (let k in obj) {
        let properyName = k.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join("-");

        style.push(`${properyName}:${obj[k]}`);
    }

    return style.join(";");
}