import React, { useEffect } from "react";

import { useForceRerender } from "@utils/component";
import { objectMerge } from "@utils/object";
import { useTranslation } from "react-i18next";

type WrapperProps = {
    property: ClipProperty;
    children: any;
    inline?: boolean;
}


export default class ClipProperty {
    public parent: any;

    public i18nKey: string = "common:unknown";
    public hidden: (parent: any) => boolean = () => false;

    public forceRerender: Function;

    
    constructor(parent: any) {
        this.parent = parent;
    }

    public exportPayload() {
        return {};
    }

    public applyPayload(data: any) {
        objectMerge(this, data, true);
    }
    

    public PropertyEditorWrapperComponent({ property, children, inline = false }: WrapperProps) {
        const { t } = useTranslation([ "mve", "common" ]);
        const [ forceRerender, key ] = useForceRerender();

        useEffect(() => {
            property.forceRerender = () => forceRerender();
        }, []);
        
        return (
            <div key={key}>
                <div className="title-row mb-1">
                    <span className="title">{ t(property.i18nKey) }</span>

                    { inline && children }
                </div>

                { !inline && children }
            </div>
        );
    }

    public isHidden(): boolean {
        return this.hidden(this.parent);
    }
}
