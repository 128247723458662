import React from "react";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const info = (message: string | JSX.Element, options: any = {}) => {
    return toast(message,
        {
            icon: <FontAwesomeIcon icon={faInfoCircle} />,
            style: {
                borderRadius: "10px",
                background: "#303030",
                color: "#ffffff"
            },
            ...options
        }
    );
}

const success = (message: string | JSX.Element, options: any = {}) => {
    return toast(message,
        {
            icon: <FontAwesomeIcon icon={faCheckCircle}/>,
            style: {
                borderRadius: "10px",
                background: "#303030",
                color: "#75e075"
            },
            ...options
        }
    );
}

const error = (message: string | JSX.Element, options: any = {}) => {
    return toast(message,
        {
            icon: <FontAwesomeIcon icon={faCheckCircle} />,
            style: {
                borderRadius: "10px",
                background: "#303030",
                color: "#e65353"
            },
            ...options
        }
    );
}

const loading = (message: string | JSX.Element, options: any = {}) => {
    return toast.loading(message,
        {
            style: {
                borderRadius: "10px",
                background: "#303030",
                color: "#ffffff"
            },
            ...options
        }
    );
}

export default { info, success, error, loading };
