import _ from "lodash"

export const objectMerge = (destObj: object, sourceObj: object, onlyWhichAlreadyExists: boolean = false) => {
    _.each(sourceObj, (v, k) => {
        let set = !onlyWhichAlreadyExists || _.has(destObj, k);

        if (set) {
            destObj[k] = v;
        }
    });

    return destObj;
}