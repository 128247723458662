import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { browserHistory } from "@/ts/router";
import { resolveLanguageAssetUrls } from "@utils/language";
import { apiRequest } from "@utils/api";
import { useForceRerender } from "@utils/component";
import { useProject } from "@providers/project";
import { useVideoPlayer } from "@providers/videoplayer";
import FrameService from "@services/FrameService";
import SubtitleEditorService from "@services/SubtitleEditorService";
import VideoPlayer from "@models/videoplayer";
import Project, { ProjectBackendPayload } from "@models/project";

import AreaControls from "./controls";
import ProjectPageKeyBinds from "./keybinds";
import { useEventListener } from "usehooks-ts";


const Context = createContext(null);
let hideTimeout;

const SharePage = () => {
    const params = useParams()
    const [forceRerender] = useForceRerender();
    const [project, setProject] = useProject();
    const [player, setPlayer] = useVideoPlayer();
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [paused, setPaused] = useState<boolean>(true);
    const [muted, setMuted] = useState(false);

    const videoContainerRef = useRef<HTMLDivElement>();

    const controlRefs = {
        progressBarRef: useRef<HTMLInputElement>(),
        progressThumbRef: useRef<HTMLDivElement>(),
        timeDisplayRef: useRef<HTMLSpanElement>(),
        controlRef: useRef<HTMLDivElement>()
    };

    const createPlayer = async () => {
        const videoFile = _.first(project.files.videos);

        if (! videoFile) {
            browserHistory.push(`/project/${project.id}/files`);
            return;
        }

        const player = new VideoPlayer(videoContainerRef.current);
        player.create();

        await player.setSource(project.getServerStoragePath(videoFile.path));

        let languageAssetUrls = resolveLanguageAssetUrls(project.languages, project.getPrimaryLanguage());
        if (languageAssetUrls?.narration) {
            player.audioNarration.src = project.getServerStoragePath(languageAssetUrls.narration);
        }
        if (languageAssetUrls?.music) {
            player.audioMusic.src = project.getServerStoragePath(languageAssetUrls.music);
        }

        SubtitleEditorService.create(player.subtitleManager.subtitlesHolderElement[0]);

        forceRerender();

        videoContainerRef.current.style.width = `calc(${videoFile.details.width/videoFile.details.height} * 100vh)`;
        player.subtitleManager.setAspectRatio(videoFile.details.width/videoFile.details.height);

        player.subtitleManager.clips = project.getClips();
        player.subtitleManager.createClipElements();

        setPlayer(player);

        return player;
    }

    const togglePaused = () => {
        const newState = player.togglePaused();
        setPaused(newState);
        

        if (newState) {
            clearTimeout(hideTimeout);
            showControls();

            FrameService.cancel("project-page-frame-update");

        } else {
            if (player.currentTime() === player.duration()){
                player.setCurrentTime(0);
                changeTimeDisplaysToMS(0);
            }

            clearTimeout(hideTimeout);
            startTimeout();
            FrameService.cancel("videoplayer-update-subtitles");
            FrameService.cancel("project-page-frame-update");
            FrameService.request("project-page-frame-update", onFrameUpdate, [ player ]);
            SubtitleEditorService.setTarget(null);
        }
        
        forceRerender();
    }

    const toggleFullscreen = () => {
        player.toggleFullscreen();
        setFullscreen(! fullscreen);
    }

    const onFrameUpdate = (player: VideoPlayer) => {
        const time = player.currentTime();
        changeTimeDisplaysToMS(player.currentTime());
    }

    const changeTimeDisplaysToMS = (ms: number) => {
        controlRefs.timeDisplayRef.current.textContent = player.getTimeString(ms, true) +" / "+ player.getTimeString(player.duration(), true);
        controlRefs.progressThumbRef.current.style.left = "calc("+(player.currentTime()/player.duration())*100+"% - 5px)";
        controlRefs.progressBarRef.current.value = ""+player.currentTime();

        player.subtitleManager.updateSubtitles();
    }

    const toggleMuted = () => {
        setMuted(player.toggleMute())
    }

    const mouseLeave = () => {
        if (!paused){
            startTimeout();
        }
    }

    const startTimeout = () => {
        hideTimeout = window.setTimeout(()=>{
            if (!controlRefs.controlRef.current.classList.contains("hidden")){
                hideControls();
            }
        }, 3000)
    }

    const hideControls = () => {
        controlRefs.controlRef.current.classList.add("hidden");
        document.documentElement.style.cursor = 'none';
    }

    const showControls = () => {
        controlRefs.controlRef.current.classList.remove("hidden");
        document.documentElement.style.cursor = 'auto';
    }

    useEffect(() => {
        if (project) {
            createPlayer();
        }
    }, [ project ]);

    useEffect(()=>{
        if (player){
            changeTimeDisplaysToMS(0);
        }
    }, [player])

    useEffect(() => {
        apiRequest<ProjectBackendPayload>("POST", `/share/${params.projectId}/get`)
            .then(res => {
                const project = new Project();
                project.applyPayload(res.result);

                setProject(project);
            });
    }, []);

    useEventListener("click", (e: any) => {
        if ((e.target.classList.contains("mve-subtitles") || e.target.classList.contains("mve-container"))){
            togglePaused();
        }
    }, videoContainerRef)

    useEventListener("mousemove", (e:any) => {
        if ((e.target.classList.contains("mve-subtitles") || e.target.classList.contains("mve-container"))){
            clearTimeout(hideTimeout)
            showControls();
            if (!paused){
                startTimeout();
            }
        } else {
            clearTimeout(hideTimeout);
        }
    }, videoContainerRef)

    useEventListener("mouseleave", (e:any) => {
        console.log("LEFT")
    }, videoContainerRef)

    useEffect(()=>{
        $(".area-playback").on("mouseleave", mouseLeave);

        return () =>{
            $(".area-playback").off("mouseleave", mouseLeave);
        }
    })

    const contextValue = { paused, fullscreen, toggleFullscreen, togglePaused, changeTimeDisplaysToMS, toggleMuted, muted };

    return (
        <div className="share-page">
            {project && <>
                <Context.Provider value={contextValue}>
                    <ProjectPageKeyBinds project={project} player={player} />

                    <div className="video-wrap">
                        <div className="area-playback" style={{ position: "relative" }}>
                            <div ref={videoContainerRef}>
                                <AreaControls project={project} player={player} togglePaused={togglePaused} controlRefs={controlRefs} changeTime={changeTimeDisplaysToMS}/>
                            </div>
                        </div>
                    </div>
                </Context.Provider>
            </>}
        </div>
    );
}

export default SharePage;

export const useProjectPageContext = () => useContext(Context);
