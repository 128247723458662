import { ProjectLanguageData, ProjectLanguages } from "../models/project";

export const regexLangKeyInExport = /^%LANG:(mve\.\d+\.\d+)%$/g;

export const recursiveLangkeyReplace = (obj: object, langKeys: object) => {
    const handler = (value) => {
        if (typeof value === "string") {
            let found = regexLangKeyInExport.exec(value);
            
            if (found) {
                let langKey = found[1];

                return langKeys[langKey] ?? langKey;
            }
        }

        if (typeof value === "object") {
            if (value === null)
                return null;

            Object.keys(value).forEach(key => {
                value[key] = handler(value[key]);
            });
        }
        
        return value;
    }

    return handler(obj);
}


export const resolveLanguageAssetUrls = (languages: ProjectLanguages, selectedLanguage: string) => {
    let language        = languages[selectedLanguage];
    let primaryLanguage = Object.values(languages).find(lang => lang.primary);

    let assetUrls: {
        narration?: string;
        subtitles?: {
            lang: string;
            path: string;
        }[],
        subtitles_alt?: {
            lang: string;
            path: string;
        }[],
        music?: string;
    } = {};

    // NARRATION
    if (language?.narration) {
        assetUrls.narration = language.narration.path;
    }

    // SUBTITLES
    if (language?.subtitles) {
        assetUrls.subtitles = Object.entries(language.subtitles)
            .map(([lang, v]) => ({
                lang: lang,
                path: v.path
            }));
    }

    // alternative subtitles from primary language
    if (! language.primary && primaryLanguage?.subtitles) {
        assetUrls.subtitles_alt = Object.entries(primaryLanguage.subtitles)
            .filter(([lang]) => ! language?.subtitles?.[lang])
            .map(([lang, v]) => ({
                lang: lang,
                path: v.path
            }));
    }

    // MUSIC
    if (language?.music) {
        assetUrls.music = language.music.path;

    } else {
        // inherit primary language's music
        if (primaryLanguage?.music) {
            assetUrls.music = primaryLanguage.music.path;
        }
    }
    
    return assetUrls;
}
