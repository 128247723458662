export const arrayFindClosest = (arr: number[], n: number, maxTolerance?: number) => {
    if (arr.length == 0)
        return false;
        
    let closest = arr.reduce((a, b) => {
        return Math.abs(b - n) < Math.abs(a - n)
            ? b
            : a;
    });

    if (! closest)
        return false;

    if (maxTolerance && Math.abs(n - closest) > maxTolerance)
        return false;
        
    return closest;
}

export const arrayShuffle = (arr: any[]) => {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
}

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
    if (old_index < 0 || new_index < 0 || old_index == new_index)
        return false;
    
    if (old_index >= arr.length || new_index >= arr.length)
        return false;

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}
