import SubtitleEditorService from "@services/SubtitleEditorService";
import { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";
import Position from "@clip/properties/position";
import Scale from "@clip/properties/scale";
import Rotation from "@clip/properties/rotation";

import { ClipBackendPayload } from "@models/project";
import Channel from "@models/channel";

import Clip, { ClipType } from "./clip";


export default class ShapeClip extends Clip {
    public type: ClipType = "ShapeClip";

    @OnChange((clip: Clip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public position: Position = new Position(this);

    @Set({ x: 30, y: 40 })
    @OnChange((clip: ShapeClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public scale: Scale = new Scale(this);

    @OnChange((clip: ShapeClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public rotation: Rotation = new Rotation(this);


    constructor(channel: Channel) {
        super(channel);

        this.editableProperties.push("position", "scale", "rotation");
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.position = this.position.exportPayload();
        payload.data.scale    = this.scale.exportPayload();
        payload.data.rotation = this.rotation.exportPayload();

        return payload;
    }

    public applyPayload(payload: ClipBackendPayload) {
        super.applyPayload(payload);
        
        if (payload.type === "ShapeClip") {
            this.position.applyPayload(payload.data.position);
            this.scale.applyPayload(payload.data.scale);
            this.rotation.applyPayload(payload.data.rotation);
        }
    }

    public getSubtitleHTML = () => {
        return `<div></div>`;
    }

    public setSubtitleElementStyle = (element: JQuery<HTMLElement>) => {
        super.setSubtitleElementStyle(element);

        let style = {
            ...this.position.toStyle(),
            ...this.scale.toStyle(),
            ...this.rotation.toStyle()
        };

        element.css(style);
    }
}
