import WebFont from "webfontloader";
import _ from "lodash";

import FrameService from "@services/FrameService";
import { getProject } from "@providers/project";

import Clip from "@models/clip/clip";
import TextClip from "@models/clip/textclip";

export type SubtitleManagerOptions = {
    currentTimeGetter: () => number;
    isPausedGetter: () => boolean;
    setPaused: (state: boolean) => void;
}

export default class SubtitleManager {
    public mveContainerElement: JQuery<HTMLElement>;
    public subtitlesHolderElement: JQuery<HTMLElement>;
    public clips: Clip[] = [];
    public getVideoCurrentTime = () => 0;
    public isVideoPaused = () => true;
    public setVideoPaused = (state: boolean) => {};

    private LOADED_WEBFONTS: string[] = [];

    constructor(mveContainerElement: JQuery<HTMLElement>, options: SubtitleManagerOptions) {
        this.mveContainerElement = mveContainerElement
            .addClass("mve-container");

        this.subtitlesHolderElement = $("<div/>")
            .addClass("mve-subtitles")
            .css({
                pointerEvents: window.IS_EDITOR ? "" : "none"
            })
            .appendTo(mveContainerElement);

        this.getVideoCurrentTime = options.currentTimeGetter;
        this.isVideoPaused       = options.isPausedGetter;
        this.setVideoPaused      = options.setPaused;

        const onVideoHolderChange = () => {
            this.updateSubtitles();
            this.rescaleSubtitles();
            this.updateEffects();
        }
        window.addEventListener("resize",           onVideoHolderChange);
        window.addEventListener("fullscreenchange", onVideoHolderChange);
    }

    public setPaused = (state: boolean = true) => {
        if (state) {
            FrameService.cancel("videoplayer-update-subtitles");

            this.getVisibleClips().forEach(clip => {
                clip.pauseEffects();
                clip.updateEffects();
            });

        } else {
            FrameService.request("videoplayer-update-subtitles", this.updateSubtitles);
            
            this.getVisibleClips().forEach(clip => {
                clip.playEffects();
                clip.updateEffects();
            });
        }
    }

    public createClipElements = (clips: Clip[] = this.clips) => {
        clips.forEach(clip => {
            if (clip.visualClip) {
                let element = clip.findSubtitleElement();
                element.remove();
    
                clip.createSubtitleElement(this.subtitlesHolderElement);
            }
        });
    }

    public updateClipVisibilities = () => {
        const currentTime = this.getVideoCurrentTime();

        let visible: Clip[] = [];
        let changed: Clip[] = [];

        this.clips.forEach(clip => {
            let newState = (clip.startTime <= currentTime && clip.startTime + clip.duration >= currentTime)
                           && !clip.channel.hidden;

            if (clip.visible !== newState) {
                clip.visible = newState;
                changed.push(clip);

                clip.onVisibilityChanged(newState);
            }

            if (newState) {
                visible.push(clip);
            }
        });

        return { visible, changed };
    }

    public getVisibleClips = () => {
        return this.clips.filter(clip => clip.visualClip && clip.visible);
    }

    public updateSubtitles = () => {
        let { visible, changed } = this.updateClipVisibilities();

        changed.forEach(clip => {
            let element = clip.findSubtitleElement();

            element.toggleClass("visible", clip.visible);

            if (clip.visible) {
                clip.initEffects();
                clip.updateEffects();
            }
        });
        
        visible.forEach(clip => {
            clip.updateEffectsStatus();
        });
    }

    public getSubtitleScaleRatio = () => {
        return this.subtitlesHolderElement.width() / 1280;
    }

    public getDimensions() {
        return [
            this.subtitlesHolderElement.width(),
            this.subtitlesHolderElement.height()
        ];
    }

    public updateEffects = () => {
        this.getVisibleClips().forEach(clip => clip.updateEffects());
    }

    public rescaleSubtitles = () => {
        this.clips.forEach(clip => clip.updateElementStyle());
    }


    public loadTranslation = (texts: { [key: string]: string }) => {
        let project = getProject();

        _.each(this.clips, clip => {
            if (clip instanceof TextClip) {
                let langKey = `mve.${project.id}.${clip.id}`;

                if (texts[langKey]) {
                    clip.text.set(texts[langKey]);
                }
            }
        });

        project.setSelectedClip(null);
        window.projectPageForceRerender();
    }


    // OVERLAY

    public showOverlay = (): JQuery<HTMLElement> => {
        let overlay = this.mveContainerElement.find(".mve-overlay");

        if (overlay.length > 0)
            return overlay;

        return $("<div/>")
            .addClass("mve-overlay")
            .appendTo(this.mveContainerElement);
    }

    public hideOverlay = (): void => {
        this.mveContainerElement.find(".mve-overlay")
            .remove();
    }


    // WEBFONT

    public loadWebFont = (name: string) => {
        if (this.LOADED_WEBFONTS.includes(name))
            return;

        WebFont.load({
            google: {
                families: [ name ]
            }
        });

        this.LOADED_WEBFONTS.push(name);
    }


    // ASPECT-RATIO (ONLY CHANGE WHEN VIDEO IS LOADED)

    public setAspectRatio = (aspectRatio: number) => {
        this.subtitlesHolderElement.css({
            width: `calc(${aspectRatio} * 100vh)`,
            maxHeight: `min(100vh, calc(100vw / ${aspectRatio}))`,
            height: `100vh`
        })
    }
}
