import { Command } from "../CommandService";

import Clip from "@models/clip/clip";

export default class ClipDeleteCommand extends Command {
    public declare target: Clip;
    public values: any;

    constructor(target: any) {
        super(target);
    }
    
    protected apply() {
        let channel = this.target.channel;
        
        channel.removeClip(this.target);

        this.target.destructor();

        window.projectPageForceRerender();
    }
    
    public undo() {
        let channel = this.target.channel;

        channel.addClip(this.target);
        this.target.initEffects();
        this.target.updateEffects();

        window.projectPageForceRerender();
    }
}
