export interface IGroup {
    name: string;
    maxQuestions: number;
}

export default class Group implements IGroup {
    public name: string;
    public maxQuestions: number;

    constructor(data: IGroup) {
        this.name         = data.name;
        this.maxQuestions = data.maxQuestions;
    }

    public toArray(): IGroup
    {
        return {
            name: this.name,
            maxQuestions: this.maxQuestions
        };
    }

    public renderSettings(): JQuery<HTMLElement>
    {
        let parent = $(`<div/>`);

        /*
         * NAME
         */
        let name = $(`
            <div class="property-group">
                <span>Name</span>
                <input type="text" />
            </div>
        `)
        .appendTo(parent);

        name.find("input")
            .val(this.name)
            .on("change", e => {
                this.name = e.currentTarget.value;
            });


        /*
         * MAX QUESTIONS
         */
        let max = $(`
            <div class="property-group">
                <span>Max questions</span>
                <input type="number" placeholder="all" />
            </div>
        `)
        .appendTo(parent);

        max.find("input")
            .val(this.maxQuestions > 0 ? this.maxQuestions : null)
            .on("change", e => {
                this.maxQuestions = parseInt(e.currentTarget.value) || 0;
            });

        return parent;
    }
}
