import { readFile } from "@utils/file";

import { Quiz, CreateEditorProps, RenderProps as QuizRenderProps } from "./quiz";
import Answer, { IAnswer } from "./answer";

export interface IQuestion {
    type: "singleselect" | "multiselect" | "trueorfalse" | "sequence" | "input" | "missingwords";
    text: string;
    score?: number;
    image?: string;
    answers: IAnswer[];
    groupIndex: number;
}

export type CreateEditorFieldsProps = CreateEditorProps & {
    triggerUpdate: Function;
    deleteQuestion: (question: Question) => void;
    deleteAnswer: (question: Question, answer: Answer) => void;
};

type RenderProps = QuizRenderProps & {
    triggerUpdate: Function;
}

export default abstract class Question {
    public type: IQuestion["type"];
    public text: IQuestion["text"];
    public score: IQuestion["score"] = null;
    public image: IQuestion["image"] = null;
    public groupIndex: IQuestion["groupIndex"];
    public answers: Answer[] = [];
    protected minAnswers: number = 1;
    protected maxAnswers: number = 10;

    constructor(props: IQuestion) {
        this.type       = props.type;
        this.text       = props.text;
        this.score      = props.score ?? null;
        this.image      = props.image ?? null;
        this.groupIndex = props.groupIndex;

        this.initAnswers(props.answers);
    }

    public toArray(): IQuestion
    {
        return {
            type:       this.type,
            text:       this.text,
            score:      this.score,
            image:      this.image,
            answers:    this.answers.map(a => a.toArray()),
            groupIndex: this.groupIndex
        };
    }

    private initAnswers(answers: IAnswer[])
    {
        this.answers = [];

        for (let a of answers) {
            let answer = new Answer(a);
            this.answers.push(answer);
        }
    }

    public render(quiz: Quiz, props: RenderProps): JQuery<HTMLElement>
    {
        let parent = $(`
            <div class="question-group question-${this.type}">
                <div class="question-header">
                    <span>${this.text}</span>
                </div>
            </div>
        `);

        /*
         * IMAGE
         */
        if (this.image) {
            $(`
                <div class="question-image">
                    <img src="${ props.formatAssetUrl(this.image) }" />
                </div>
            `)
            .appendTo(parent);
        }

        /*
         * ANSWERS
         */
        this.createAnswerFields(quiz, props)
            .appendTo(parent);

        if (quiz.currentView === "solutions") {
            this.highlightSolutions(parent);   
        }
            
        return parent;
    }
    
    public createEditorFields(quiz: Quiz, props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = $(`
            <div class="question-group question-${this.type}">
                <div class="question-header">
                    <span>${this.type}</span>

                    <div button="delete" class="icon-button big trash"></div>
                </div>
            </div>
        `)
        // delete
        .on("click", ".question-header > [button=delete]", e => {
            props.deleteQuestion(this);
            parent.remove();
        });


        /*
         * QUESTION
         */
        let title = $(`
            <div class="property-group">
                <span>Question</span>
                <input type="text" placeholder="Question..." />
            </div>
        `)
        .on("change", "input", e => {
            this.text = e.currentTarget.value;
        })
        .appendTo(parent);


        /*
         * SCORE
         */
        let score = $(`
            <div class="property-group">
                <span>Score</span>
                <input type="number" placeholder="${ this.getMaxScore() }" />
            </div>
        `)
        .on("change", "input", e => {
            let score = parseInt(e.currentTarget.value);

            if (! score) {
                score = null;
            }
            
            this.score = score;
        })
        .appendTo(parent);


        /*
         * IMAGE
         */
        let image = $(`
            <div class="property-group">
                <span>Image</span>

                ${this.image ? `
                    <div>
                        <img src="${ props.formatAssetUrl(this.image) }" />
                    </div>

                    <div button="delete_image" class="icon-button big trash"></div>
                    
                ` : `
                    <div button="add_image" class="icon-button file">
                        Select
                    </div>
                `}
            </div>
        `)
        .on("click", "[button=add_image]", e => {
            let input = $<HTMLInputElement>(`<input type="file" accept="image/*" style="display: none">`)
                .appendTo(image)
                .trigger("click");
            
                input.on("input", async e => {
                    let file = input[0].files[0];
                    
                    if (file) {
                        let data = await readFile(file);
                        let name = await props.onAssetUpload(data);

                        if (name) {
                            this.image = name;
                            props.triggerUpdate();
                        }
                    }
                });

                $(window).one("focus", e => {
                    setTimeout(() => {
                        input.remove();
                    }, 100);
                });
        })
        .on("click", "[button=delete_image]", async e => {
            await props.onAssetDelete(this.image);
            
            this.image = null;
            props.triggerUpdate();
        })
        .appendTo(parent);


        // defaults
        title.find("input").val(this.text);
        if (this.score) {
            score.find("input").val(this.score);
        }


        /*
         * ANSWERS
         */
        this.createAnswerEditorFields(props)
            .appendTo(parent);


        /*
         * BUTTONS
         */
        $(`
            <div class="icon-button add" style="margin-top: 1rem">
                Add answer
            </div>
        `)
        .on("click", e => {
            this.addNewAnswer();
            props.triggerUpdate();
        })
        .toggle(this.answers.length < this.maxAnswers)
        .appendTo(parent);

        return parent;
    }

    public abstract highlightSolutions(parent: JQuery<HTMLElement>);

    protected createAnswerFields(quiz: Quiz, props: RenderProps): JQuery<HTMLElement>
    {
        return $("<div />");
    }

    protected createAnswerEditorFields(props: CreateEditorProps): JQuery<HTMLElement>
    {
        return $("<div />");
    }

    protected createNewAnswer(): Answer
    {
        return new Answer({
            text:         "",
            correctValue: this.answers.length === 0
        });
    }

    public addNewAnswer()
    {
        let answer = this.createNewAnswer();

        this.answers.push(answer);
    }

    public abstract isAnswered(): boolean

    public abstract isAnswerCorrect(answer: Answer): boolean

    public abstract getMaxScore(): number

    public abstract getScore(): number
}
