import { Quiz } from "../quiz";
import Answer from "../answer";
import Question, { CreateEditorFieldsProps, IQuestion } from "../question";

export default class QuestionTrueOrFalse extends Question {
    constructor(props: IQuestion) {
        super(props);
    }

    protected createAnswerFields(quiz: Quiz, props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerFields(quiz, props);

        let answersCont = $(`
            <div class="answers"></div>
        `)
        .appendTo(parent);

        for (let i in this.answers) {
            let answer = this.answers[i];

            let elem = $(`
                <div answer-index="${i}" class="answer-group">
                    <div style="display: flex; align-items: center; gap: 5px">
                        <div button="select" class="icon-button big thumbs up ${answer.value === true  ? "active" : ""}"></div>
                        <div button="select" class="icon-button big thumbs down ${answer.value === false ? "active" : ""}"></div>
                    </div>

                    <span>${ answer.text }</span>
                </div>
            `)
            .appendTo(answersCont);

            if (quiz.currentView === "game") {
                // select true
                elem.on("click", "[button=select].up", e => {
                    answer.setValue(true);
                    props.triggerUpdate();
                });
                
                // select false
                elem.on("click", "[button=select].down", e => {
                    answer.setValue(false);
                    props.triggerUpdate();
                });
            }
        }

        return parent;
    }

    protected createAnswerEditorFields(props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerEditorFields(props);

        $(`
            <div>
                <div class="property-group">
                    <span>Answers</span>
                </div>

                <div class="answers">

                </div>
            </div>
        `)
        .appendTo(parent);

        let answersCont = parent.find(".answers");

        for (let answer of this.answers) {
            let elem = $(`
                <div class="answer-group">
                    <div button="correct" class="icon-button big thumbs ${answer.correctValue ? "up" : "down"}"></div>

                    <input type="text" placeholder="Answer..." />
                </div>
            `)
            // correct
            .on("click", "[button=correct]", e => {
                answer.correctValue = ! answer.correctValue;
                
                props.triggerUpdate();
            })
            // text
            .on("change", "input", e => {
                answer.text = e.currentTarget.value;
            })
            .appendTo(answersCont);
            
            // delete
            $(`<div class="icon-button big trash"></div>`)
                .on("click", e => {
                    props.deleteAnswer(this, answer);
                })
                .toggle(this.answers.length > this.minAnswers)
                .appendTo(elem);

            // defaults
            elem.find("input").val(answer.text);
        }

        return parent;
    }

    public highlightSolutions(parent: JQuery<HTMLElement>)
    {
        this.answers.forEach((answer, index) => {
            let type = this.isAnswerCorrect(answer)
                ? "correct"
                : "incorrect";
            
            parent.find(`.answers .answer-group[answer-index="${index}"]`)
                .addClass(type)
                .find("[button='select']:not(.active)")
                    .hide();
        });
    }

    protected createNewAnswer(): Answer
    {
        return new Answer({
            text:         "",
            correctValue: true
        });
    }

    public isAnswered(): boolean
    {
        return this.answers.filter(answer => answer.value === null).length === 0;
    }

    public isAnswerCorrect(answer: Answer): boolean
    {
        return answer.correctValue === answer.value;
    }

    public getMaxScore(): number {
        if (typeof this.score === "number")
            return this.score;
        
        return this.answers.length;
    }

    public getScore(): number {
        let scoreForCorrectAnswer = this.getMaxScore() / this.answers.length;

        return this.answers.reduce(
            (score, answer) => this.isAnswerCorrect(answer)
                ? score + scoreForCorrectAnswer
                : score,
            0
        );
    }
}
