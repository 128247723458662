import { Quiz } from "../quiz";
import Answer from "../answer";
import Question, { CreateEditorFieldsProps, IQuestion } from "../question";

export default class QuestionMultiSelect extends Question {
    constructor(props: IQuestion) {
        super(props);
    }

    protected createAnswerFields(quiz: Quiz, props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerFields(quiz, props);

        let answersCont = $(`
            <div class="answers"></div>
        `)
        .appendTo(parent);

        for (let i in this.answers) {
            let answer = this.answers[i];

            let elem = $(`
                <div answer-index="${i}" class="answer-group">
                    <div class="icon-button big square ${answer.value ? "active" : ""}"></div>

                    <span>${ answer.text }</span>
                </div>
            `)
            
            .appendTo(answersCont);
            
            if (quiz.currentView === "game") {
                elem.on("click", e => {
                    answer.setValue(! answer.value);
    
                    props.triggerUpdate();
                });
            }
        }

        return parent;
    }

    protected createAnswerEditorFields(props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerEditorFields(props);

        $(`
            <div>
                <div class="property-group">
                    <span>Answers</span>
                </div>

                <div class="answers">

                </div>
            </div>
        `)
        .appendTo(parent);

        let answersCont = parent.find(".answers");

        for (let answer of this.answers) {
            let elem = $(`
                <div class="answer-group">
                    <div button="correct" class="icon-button big check ${answer.correctValue ? "active" : ""}"></div>

                    <input type="text" placeholder="Answer..." />
                </div>
            `)
            // correct
            .on("click", "[button=correct]", e => {
                let checkedCount = this.answers.filter(a => a.correctValue).length;
                let newState     = ! answer.correctValue;

                if (!newState && checkedCount === 1)
                    return false;

                answer.correctValue = newState;
                props.triggerUpdate();
            })
            // text
            .on("change", "input", e => {
                answer.text = e.currentTarget.value;
            })
            .appendTo(answersCont);

            // delete
            $(`<div class="icon-button big trash"></div>`)
                .on("click", e => {
                    props.deleteAnswer(this, answer);
                })
                .toggle(this.answers.length > this.minAnswers)
                .appendTo(elem);

            // defaults
            elem.find("input").val(answer.text);
        }

        return parent;
    }

    public highlightSolutions(parent: JQuery<HTMLElement>)
    {
        this.answers.forEach((answer, index) => {
            let type = "neutral";

            if (answer.correctValue) {
                type = "correct";
            } else if (answer.value && ! answer.correctValue) {
                type = "incorrect";
            }
            
            parent.find(`.answers .answer-group[answer-index="${index}"]`)
                .addClass(type);
        });
    }

    public isAnswered(): boolean
    {
        return this.answers.filter(answer => answer.value).length > 0;
    }

    public isAnswerCorrect(answer: Answer): boolean
    {
        return !! (answer.correctValue && answer.value);
    }

    public getMaxScore(): number
    {
        if (typeof this.score === "number")
            return this.score;

        return this.answers.reduce(
            (score, answer) => answer.correctValue
                ? ++score
                : score,
            0
        );
    }

    public getScore(): number
    {
        let correctAnswersCount = this.answers.reduce(
            (count, answer) => answer.correctValue
                ? ++count
                : count,
            0
        );
        let scoreForCorrectAnswer = this.getMaxScore() / correctAnswersCount;

        return this.answers.reduce(
            (score, answer) => this.isAnswerCorrect(answer)
                ? score + scoreForCorrectAnswer
                : score,
            0
        );
    }
}
