import React from "react";
import classNames from "classnames";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    children: any;
    className?: string;
}

const PropertyNote = ({ children, className }: Props) => (
    <div className={classNames("d-flex w-100 rounded", className)} style={{ background: "#3d3d3d" }}>
        <div className="p-2">
            <FontAwesomeIcon icon={faCircleInfo} />
        </div>

        <div className="p-2 ps-0">
            { children }
        </div>
    </div>
)

export default PropertyNote;
