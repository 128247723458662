import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";

import NumberInput from "@components/inputs/number";


export default class NumberProperty extends ClipProperty {
    public title: string = "Number";
    public min: number = 0;
    public max: number = null;
    public step: number = 1;
    
    public value: number = 0;


    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: number) {
        if (typeof this.min === "number") {
            value = Math.max(this.min, value);
        }
        if (typeof this.max === "number") {
            value = Math.min(this.max, value);
        }
        
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { value })
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }
    
    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);

        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);
    
        return (
            <div>
                <NumberInput
                    className="w-100"
                    defaultValue={value}
                    min={this.min}
                    max={this.max}
                    step={this.step}
                    onInput={e => setValue(e.currentTarget.valueAsNumber)}
                />
            </div>
        );
    }
}
