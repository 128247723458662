export interface IAnswer {
    text: string;
    correctValue: boolean | string | number;
}

export default class Answer {
    public text: IAnswer["text"];
    public correctValue: IAnswer["correctValue"];

    public value: boolean | string | number | null = null;

    constructor(props: IAnswer) {
        this.text         = props.text;
        this.correctValue = props.correctValue;
    }

    public toArray(): IAnswer
    {
        return {
            text:         this.text,
            correctValue: this.correctValue
        };
    }

    public setValue(value: boolean | string | number | null)
    {
        this.value = value;
    }
}
