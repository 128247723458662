import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";


export default class Select extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.select";
    
    public value: string = "";

    public options: {
        value: string;
        label: string;
    }[] = [];


    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: string) {
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { value })
        cmd.execute();
    }


    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);
    
        return (
            <div>
                <select value={value} onChange={e => setValue(e.currentTarget.value)}>
                    <option value="">-</option>

                    {this.options.map(v => (
                        <option key={ v.value } value={ v.value }>{ v.label }</option>
                    ))}
                </select>
            </div>
        );
    }
}
