import { apiRequest } from "@utils/api";

export default class Client {
    public signedIn: boolean = false;
    public id?: number;
    public email?: string;
    public name?: string;
    public avatar_url?: string;

    constructor() {
        if (window.G_USER && Object.keys(window.G_USER).length > 0) {
            this.loadUserData(window.G_USER);
        }
    }

    private loadUserData = (userData: any) => {
        for (let key in userData) {
            if (userData.hasOwnProperty(key)) {
                this[key] = userData[key];
            }
        }

        this.signedIn = true;
    }

    /* AUTH */
    public signOut = () => {
        location.href = "/auth/signout";
    }

    public isSignedIn = () => this.signedIn;

    /* GETTERS */
    public get = (prop: string) => {
        if (! this.isSignedIn())
            return undefined;

        return this[prop];
    }
}
