import React from "react";

import { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import AnimPlayType from "@enums/animplaytype";
import AnimPriorityGroup from "@enums/animprioritygroup";

import ClipEffect, { AnimPriority } from "@models/clipeffect";

import NumberProperty from "@clip/properties/number";


export default class FadeOutEffect extends ClipEffect {
    public type = "FadeOutEffect";
    public animPlayType = AnimPlayType.End;
    public priority: AnimPriority = [AnimPriorityGroup.High, 0];
    
    @Set({ i18nKey: "pages.project.property_name.duration", max: 10000 })
    @OnChange((effect: FadeOutEffect) => {
        effect.create();
    })
    public duration: NumberProperty = new NumberProperty(this);


    constructor() {
        super();

        this.duration.value = 1000;
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.duration = this.duration.exportPayload();

        return payload;
    }

    
    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.duration.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        const wrapper = subtitleElement.find(".subtitle-wrapper");
        
        // defaults
        this.timeline
            .add({
                targets:  wrapper.get(0),
                duration: 1,
                opacity:  1
            }, "-=1");

        // show
        this.timeline
            .add({
                targets:  wrapper.get(0),
                duration: this.duration.value,
                opacity:  0
            });
    }
}
