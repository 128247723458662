import React from "react";
import katex from "katex";

import ClipEffect from "@models/clipeffect";
import TextClip from "@models/clip/textclip";

export default class LatexEffect extends ClipEffect {
    public type = "LatexEffect";
    public declare clip: TextClip;

    constructor() {
        super();
    }

    public init() {
        super.init();

        this.clip.text.simple = true;
    }

    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>

            </this.PropertyEditorWrapperComponent>
        );
    }

    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const content = subtitleElement.find(".subtitle-content");

        let html = katex.renderToString(this.clip.text.value, {
            output: "html",
            throwOnError: false,
            displayMode: true
        });

        if (! html) {
            html = this.clip.text.value;
        }

        content.html(html);
    }
}
