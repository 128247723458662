import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { CKEditor } from "ckeditor4-react";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";


export default class Text extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.text";
    public simple: boolean = false;

    public value: string = "";
    

    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }

    
    public set(value: string) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            console.log(value);
            
            this.set(value);
        }, [ value ]);
        
        return (
            <div>
                {this.simple ? (
                    <textarea
                        defaultValue={value}
                        onChange={e => {
                            let data = e.currentTarget.value.replaceAll("\n", "<br>");
                            
                            setValue(data);
                        }}
                    />
                    
                ) : (
                    <CKEditor
                        initData={value}
                        editorUrl="/static/ckeditor4/ckeditor.js"
                        config={{
                            extraPlugins: [ "removeformat" ],
                            toolbarGroups: [
                                { name: "basicstyles", groups: [ "basicstyles" ] },
                                { name: "cleanup" },
                                "/",
                                { name: "list" }
                            ],
                            enterMode: 2,
                            shiftEnterMode: 1,
                            forcePasteAsPlainText: true,
                            useComputedState: false,
                            allowedContent: true,
                            skin: "ckeditor-basic-moono-dark-biskrem"
                        }}
                        onChange={e => {
                            let data = e.editor.getData();

                            let temp = $("<div/>")
                                .html(data);

                            setValue(temp.html());
                        }}
                    />
                )}
            </div>
        );
    }
}
