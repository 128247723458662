import React from "react";

import ClipEffect from "@models/clipeffect";
import TextClip from "@models/clip/textclip";


export default class UppercaseEffect extends ClipEffect {
    public type = "UppercaseEffect";
    public declare clip: TextClip;

    constructor() {
        super();
    }

    public init() {
        super.init();
    }

    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>

            </this.PropertyEditorWrapperComponent>
        );
    }

    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const content = subtitleElement.find(".subtitle-content");

        content.css("text-transform", "uppercase");
    }
}
