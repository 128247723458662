export const execAll = (pattern: RegExp, input: string) => {
    if (! pattern.global)
        throw new Error("Pattern has to be global.");

    let results: RegExpExecArray[] = [];
    let match: RegExpExecArray;
    
    do {
        match = pattern.exec(input);
        if (match) {
            results.push(match);
        }
    } while (match);

    return results;
}
