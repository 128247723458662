import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { Form } from "react-bootstrap";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";


export default class Switch extends ClipProperty {
    public title: string = "Switch";

    public value: boolean = false;


    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: boolean) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this} inline={true}>
                <this.PropertyEditor />
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);
    
        return (
            <div>
                <Form.Check
                    type="switch"
                    defaultChecked={value}
                    onChange={e => setValue(e.currentTarget.checked)}
                />
            </div>
        );
    }
}
