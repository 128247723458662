import React from "react";
import classNames from "classnames";
import { faArrowUp, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Anchor from "@enums/anchorposition";
import AnchorPosition from "@enums/anchorposition";

export type AnchorSelectorLayout = "full" | "horizontal" | "vertical";

type Props = {
    value: Anchor;
    layout?: AnchorSelectorLayout;
    allowCenterAlign?: boolean;
    onSelect: Function;
}



const AnchorSelector = ({ value, layout = "full", allowCenterAlign = true, onSelect } : Props) => {
    let anchorValues   = Object.values(Anchor);
    let visibleAnchors = anchorValues;
    
    switch(layout) {
        case "horizontal":
            visibleAnchors = anchorValues.filter(anchor => anchor.split("-")[1] === "center");
            break;

        case "vertical":
            visibleAnchors = anchorValues.filter(anchor => anchor.split("-")[0] === "center");
            break;
    }

    if (! allowCenterAlign) {
        visibleAnchors = visibleAnchors.filter(anchor => anchor !== AnchorPosition.CenterCenter);
    }
    
    return (
        <div className="anchor-selector">
            {anchorValues.map(anchor => (
                <div
                    key={anchor}
                    className={classNames(
                        anchor,
                        {
                            "selected": anchor === value,
                            "disabled": !visibleAnchors.includes(anchor)
                        }
                    )}
                    onClick={_ => onSelect(anchor)}
                >
                    <FontAwesomeIcon icon={anchor === Anchor.CenterCenter ? faCircleDot : faArrowUp} />
                </div>
            ))}
        </div>
    );
}

export default AnchorSelector;
