import Clip, { ClipType } from "./clip";

import { joinUrl } from "@utils/url";

import { getSubtitleManager } from "@providers/subtitlemanager";
import { Set } from "@decorators/set";
import { OnChange } from "@decorators/onchange";
import Switch from "@clip/properties/switch";
import Text from "@clip/properties/text";
import Select from "@clip/properties/select";

import Channel from "@models/channel";
import { ClipBackendPayload } from "@models/project";
import { Quiz } from "@models/quiz/quiz";


export default class MarkerClip extends Clip {
    public type: ClipType = "MarkerClip";
    public isDurationEditable: boolean = false;
    public visualClip: boolean = false;

    private activeQuiz: Quiz;

    @Set({ i18nKey: "pages.project.property_name.time_interval" })
    @OnChange((clip: MarkerClip) => {
        clip.isDurationEditable = clip.isTimeInterval.value;
        clip.duration           = 5000;
        window.projectPageForceRerender();
    })
    public isTimeInterval: Switch = new Switch(this);

    @Set({ i18nKey: "pages.project.property_name.description", simple: true })
    public description: Text = new Text(this);

    @Set({ i18nKey: "pages.project.property_name.quizzes" })
    @OnChange((clip: MarkerClip) => {
        console.log(clip.quiz);
    })
    public quiz: Select = new Select(this);


    constructor(channel: Channel) {
        super(channel);

        let project = this.getProject();
        
        this.quiz.options = project.quizzes.map(
            q => ({ value: String(q.id), label: q.title })
        );

        this.editableProperties.push("description", "quiz");
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.isTimeInterval = this.isTimeInterval.exportPayload();
        payload.data.description    = this.description.exportPayload();
        payload.data.quiz           = this.quiz.exportPayload();

        return payload;
    }

    public applyPayload(payload: ClipBackendPayload) {
        super.applyPayload(payload);
        
        if (payload.type === "MarkerClip") {
            this.isTimeInterval.applyPayload(payload.data.isTimeInterval);
            this.description.applyPayload(payload.data.description);
            this.quiz.applyPayload(payload.data.quiz);

            this.isDurationEditable = this.isTimeInterval.value;
        }
    }


    public onVisibilityChanged = (visible: boolean) => {
        if (visible) {
            this.showQuiz();
        
        } else {
            let [manager] = getSubtitleManager();
            manager.hideOverlay();

            this.activeQuiz.destroy();
            delete this.activeQuiz;
        }
    }

    
    public showQuiz()
    {
        if (! this.quiz.value)
            return;

        let project  = this.getProject();
        let quizData = project.quizzes.find(q => q.id === parseInt(this.quiz.value));

        if (! quizData)
            return;

        let [manager] = getSubtitleManager();

        manager.setVideoPaused(true);

        let overlay = manager.showOverlay();

        let parent = $("<div/>")
            .appendTo(overlay);

        this.activeQuiz = new Quiz(quizData);
        
        this.activeQuiz.render({
            parent:         parent[0],
            formatAssetUrl: (name: string) => joinUrl(process.env.APP_URL, `var/quizzes/${quizData.id}/assets`, name)
        });
    }
}
