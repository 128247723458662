import "reflect-metadata";

const MetadataKey = Symbol("SetDecorator");

export const Set = (data: any) => {
    return function(target: object, propertyKey: string) {
        let properties: any = Reflect.getMetadata(MetadataKey, target) ?? {};

        properties[propertyKey] = { data };

        Reflect.defineMetadata(MetadataKey, properties, target);
    }
}

const getProperties = (origin: any): object => {
    const properties: any = Reflect.getMetadata(MetadataKey, origin) ?? {};
    let result: any = [];

    for (let propertyKey in properties) {
        result[propertyKey] = origin[propertyKey];
    }

    return result;
}

const execute = (origin: any) => {
    const properties: any = Reflect.getMetadata(MetadataKey, origin) ?? {};

    for (let propertyKey in properties) {
        let property = origin[propertyKey];
        let data     = properties[propertyKey].data;

        for (let k in data) {
            property[k] = data[k];
        }
    }
}


export default { getProperties, execute };
