import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import config from "@config";
import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";


export default class Font extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.font";
    
    public value: string = "Roboto";


    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: string) {
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { value })
        cmd.execute();
    }

    public toStyle() {
        return {
            fontFamily: this.value
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);
    
        return (
            <div>
                <select value={value} onChange={e => setValue(e.currentTarget.value)}>
                    {config.FONTS.map(font => (
                        <option key={ font.name } value={ font.name }>{ font.name }</option>
                    ))}
                </select>
            </div>
        );
    }
}
