import React, { useEffect, useState, useRef } from "react";
import Pickr from "@simonwep/pickr";

import { createColorPicker } from "@utils/color";
import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";
import ToastService from "@services/ToastService";

export default class Color extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.color";

    public value: string = "#ffffff";

    private pickr: Pickr;


    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: string) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public toStyle() {
        return {
            color: this.value
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this} inline={true}>
                <this.PropertyEditor />
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const ref = useRef<HTMLDivElement>();
        const [value, setValue] = useState(this.value);

        const updateColor = (value) => {
            setValue(value);
            this.pickr.setColor(value);
        }

        const handleRightClick = (e) => {
            e.preventDefault();

            if (!window.EyeDropper) {
                ToastService.error("Your browser does not support this feature!");
                return;
            }

            this.pickr.hide();

            const eyeDropper = new window.EyeDropper();

            eyeDropper.open()
                .then((result) => {
                    updateColor(result.sRGBHex);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        useEffect(() => {
            this.set(value);
        }, [ value ]);

        useEffect(() => {
            if (! ref.current)
                return;

            this.pickr = createColorPicker(ref.current, updateColor, value);

            return () => {
                this.pickr.destroyAndRemove();
            }
        }, [ ref ]);

        return (
            <div onContextMenu={(e) =>  handleRightClick(e)}>
                <div ref={ref}/>
            </div>
        );
    }
}
