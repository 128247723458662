import React from "react";
import anime from "animejs";

import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import AnimPriorityGroup from "@enums/animprioritygroup";

import ClipEffect, { AnimPriority } from "@models/clipeffect";
import TextClip from "@models/clip/textclip";

import NumberProperty from "@clip/properties/number";


export default class ListSlideUpEffect extends ClipEffect {
    public type = "ListSlideUpEffect";
    public priority: AnimPriority = [AnimPriorityGroup.High, 0];

    @Set({ i18nKey: "pages.project.property_name.show_duration_per_row", max: 3000 })
    @OnChange((effect: ListSlideUpEffect) => {
        effect.create();
    })
    public duration: NumberProperty = new NumberProperty(this);

    @Set({ i18nKey: "pages.project.property_name.delay_per_row", max: 3000 })
    @OnChange((effect: ListSlideUpEffect) => {
        effect.create();
    })
    public delay: NumberProperty = new NumberProperty(this);


    constructor() {
        super();

        this.duration.value = 500;
        this.delay.value    = 250;
    }

    public init() {
        super.init();
        
        if (this.clip instanceof TextClip) {
            OnChangeDecorator.subscribe(this.clip, [], () => {
                this.create();
            });
        }
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.duration = this.duration.exportPayload();
        payload.data.delay    = this.delay.exportPayload();

        return payload;
    }


    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.duration.getPropertyEditorComponent() }
                { this.delay.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        let rows = subtitleElement.find("ul > li").add(subtitleElement.find("ol > li"));
        
        // defaults
        this.timeline
            .add({
                targets:    rows.toArray(),
                duration:   1,
                opacity:    0,
                translateY: "50%"
            });
        
        // show
        this.timeline
            .add({
                targets:    rows.toArray(),
                duration:   this.duration.value,
                delay:      anime.stagger(this.duration.value + this.delay.value),
                opacity:    1,
                translateY: "0%"
            });
    }
}
