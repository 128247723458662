import md5 from "md5";
import interact from "interactjs";

import { execAll } from "@utils/regex";
import { domShuffle } from "@utils/dom";
import { Quiz } from "../quiz";
import Question, { CreateEditorFieldsProps, IQuestion } from "../question";
import Answer from "../answer";

export default class QuestionMissingWords extends Question {
    protected maxAnswers: number = 1;

    constructor(props: IQuestion) {
        super(props);
    }

    protected createAnswerFields(quiz: Quiz, props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerFields(quiz, props);

        $(`
            <div>
                <div class="answers"></div>

                <div class="words-list"></div>
            </div>
        `)
        .appendTo(parent);

        let answersCont = parent.find(".answers");
        let wordsCont   = parent.find(".words-list");

        for (let i in this.answers) {
            let answer = this.answers[i];

            let text = $(`<div class="missingwords-wrapper">${ answer.text }</div>`);

            let dropzones: {
                [index: number]: {
                    dropzoneElem: JQuery<HTMLElement>,
                    dndElem: JQuery<HTMLElement>,
                    correctText: string
                }
            } = {};

            text.find("missing")
                .each((i, v) => {
                    let text    = $(v).html();
                    let dndElem = $(`<div class='dnd'>${text}</div>`)
                        .attr("hash", md5(text))
                        .appendTo(wordsCont);

                    let dropzoneElem = $(`<div dropzone-index='${i}' class='dropzone'></div>`);

                    dropzones[i] = {
                        dropzoneElem,
                        dndElem, 
                        correctText: text
                    };

                    const position = { x: 0, y: 0 };

                    
                    if (quiz.currentView === "game") {
                        interact(dndElem[0])
                            .draggable({
                                modifiers: [
                                    interact.modifiers.restrictRect({
                                        restriction: parent[0]
                                    })
                                ],
                                listeners: {
                                    move: e => {
                                        position.x += e.dx;
                                        position.y += e.dy;
                                        dndElem.css("transform", `translate(${position.x}px, ${position.y}px)`);
                                    },
                                    end: e => {
                                        position.x = 0;
                                        position.y = 0;
                                        dndElem.css("transform", `translate(${position.x}px, ${position.y}px)`);
                                        
                                        let target = $(e.relatedTarget);

                                        if ( target.hasClass("dropzone") ) {
                                            if ( ! target.is(":empty") ) {
                                                target.children().appendTo(wordsCont);
                                            }
                                            dndElem.appendTo(target);

                                        } else {
                                            dndElem.appendTo(wordsCont);
                                        }

                                        updateAnswerValue();
                                    }
                                }
                            })
                            .on("tap", e => {
                                // already inside a dropzone
                                if ( dndElem.parent().hasClass("dropzone") ) {
                                    dndElem.appendTo(wordsCont);
                                    updateAnswerValue();
                                    return;    
                                }

                                // select
                                wordsCont.find(".dnd").removeClass("selected");
                                dndElem.addClass("selected");
                            });
                        
                        interact(dropzoneElem[0])
                            .dropzone({
                                overlap: "pointer"
                            })
                            .on("tap", e => {
                                let selectedDndElem = wordsCont.find(".dnd.selected");

                                if (selectedDndElem.length === 1) {
                                    selectedDndElem.appendTo(dropzoneElem);
                                    updateAnswerValue();
                                }

                                selectedDndElem.removeClass("selected");
                            });
                    }

                    $(v).replaceWith(dropzoneElem);
                });

            $(`<div answer-index="${i}" class="answer-group"></div>`)
                .append(text)
                .appendTo(answersCont);

            let updateAnswerValue = () => {
                let clone = text.clone();
                
                for (let index in dropzones) {
                    let data = dropzones[index];

                    let dzElem       = clone.find(`[dropzone-index='${index}']`);
                    let childDndElem = dzElem.children(".dnd");

                    let dndValueElem = $(`<dnd-value />`)
                        .attr("dropzone-index", index);

                    if (childDndElem.length === 1) {
                        let currentText = childDndElem.html();
                        let isCorrect   = currentText === data.correctText;

                        dndValueElem.html(currentText)
                            .attr("correct", String(isCorrect));
                    }

                    dzElem.replaceWith(dndValueElem);
                }
                
                answer.setValue( clone.html() );
            }

            let getDNDElemFromText = (text: string): JQuery<HTMLElement> | null => {
                for (let v of wordsCont.children(".dnd").toArray()) {
                    let dndText = $(v).html();

                    if (dndText === text) {
                        return $(v);
                    }
                }

                return null;
            }

            // initial state
            if (! answer.value) {
                updateAnswerValue();
            }

            // restore state
            let elem       = $(`<div>${ answer.value }</div>`);
            let valueElems = elem.find("dnd-value");

            valueElems.each((i, v) => {
                let dzIndex     = parseInt( $(v).attr("dropzone-index") );
                let currentText = $(v).html();
                
                let dzElem  = dropzones[dzIndex].dropzoneElem;
                let dndElem = getDNDElemFromText(currentText);
                
                if (dndElem) {
                    dndElem.appendTo(dzElem);
                }
            });
        }

        domShuffle(wordsCont[0]);

        return parent;
    }

    protected createAnswerEditorFields(props: CreateEditorFieldsProps): JQuery<HTMLElement>
    {
        let parent = super.createAnswerEditorFields(props);

        $(`
            <div>
                <div class="property-group">
                    <span>Text</span>
                </div>

                <div class="answers"></div>

                <div class="words-list"></div>
            </div>
        `)
        .appendTo(parent);

        let answersCont = parent.find(".answers");
        let wordsCont   = parent.find(".words-list");

        for (let i in this.answers) {
            let answer = this.answers[i];

            let elem = $(`
                <div class="answer-group">
                    <div answer-text contenteditable="true">${ answer.text }</div>
                </div>
            `)
            // text
            .on("change", "[answer-text]", e => {
                answer.text = e.currentTarget.value;
            })
            // select word
            .on("mouseup", "[answer-text]", e => {
                let selection = window.getSelection();
                let selected  = selection.toString();

                if (selection.anchorNode && selection.focusNode && selection.anchorNode === selection.focusNode && selection.anchorOffset !== selection.focusOffset) {
                    wordsCont.find("[add-word]").remove();

                    let addBtn = $(`
                        <div add-word class="icon-button add">${ selected }</div>
                    `)
                    .on("click", e => {
                        $(e.currentTarget).remove();
                        document.execCommand("insertHTML", false, `<missing contenteditable="false">${ selected }</missing>`);
                        updateAnswerText();
                        listAddedWords();
                    })
                    .prependTo(wordsCont);

                    $(window).one("mousedown", e => {
                        // ha nem a gombra kattint, akkor megszűntetjük a selectiont
                        // nehogy máshová szúrja be a szót
                        if ( $(e.target).closest(addBtn).length == 0 ) {
                            wordsCont.find("[add-word]").remove();
                        }
                    });
                }
            })
            .appendTo(answersCont);

            // delete
            $(`<div class="icon-button big trash"></div>`)
                .on("click", e => {
                    props.deleteAnswer(this, answer);
                })
                .toggle(this.answers.length > this.minAnswers)
                .appendTo(elem);

            // defaults
            elem.find("missing")
                .attr("contenteditable", "false");

            let updateAnswerText = () => {
                let clone = elem.find("[answer-text]").clone();
                clone.find("missing")
                     .removeAttr("contenteditable");

                answer.text = clone.html().replaceAll("&nbsp;", " ");
                clone.remove();
            }

            let listAddedWords = () => {
                wordsCont.empty();

                elem.find("[answer-text] missing")
                    .each((i, v) => {
                        $(`
                            <div class="icon-button trash">${ $(v).text() }</div>
                        `)
                        // DELETE WORD
                        .on("click", e => {
                            $(v).replaceWith( $(v).text() );
                            $(e.currentTarget).remove();
                            updateAnswerText();

                            // szétbontott textnode-ok összevonása
                            elem.find("[answer-text]")[0].normalize();
                        })
                        .prependTo(wordsCont);
                    });
            }

            listAddedWords();
        }

        return parent;
    }

    public highlightSolutions(parent: JQuery<HTMLElement>)
    {
        for (let i in this.answers) {
            let answer = this.answers[i];

            let group     = parent.find(`.answers .answer-group[answer-index="${i}"]`);
            let elem      = $(`<div>${ answer.value }</div>`);
            let dropzones = elem.find("dnd-value");
            
            dropzones.each((i, v) => {
                let dzIndex   = $(v).attr("dropzone-index");
                let isCorrect = $(v).attr("correct") === "true";

                let type = isCorrect
                    ? "correct"
                    : "incorrect";

                group.find(`[dropzone-index='${dzIndex}']`)
                    .addClass(type);
            });
        }
    }

    private getMissingWordsInAnswer(answer: Answer)
    {
        return execAll(/<missing>(.*?)<\/missing>/g, answer.text);
    }

    public isAnswered(): boolean
    {
        for (let answer of this.answers) {
            if (typeof answer.value !== "string")
                return false;

            let elem       = $(`<div>${ answer.value }</div>`);
            let emptyZones = elem.find("dnd-value:empty");
            
            if (emptyZones.length > 0) {
                return false;
            }
        }
        
        return true;
    }

    // NOTE: unused
    public isAnswerCorrect(): boolean
    {
        return false;
    }

    public getMaxScore(): number
    {
        if (typeof this.score === "number")
            return this.score;

        return this.answers.reduce(
            (score, answer) => score + this.getMissingWordsInAnswer(answer).length,
            0
        );
    }

    public getScore(): number
    {
        let scoreForCorrectAnswer = 1;

        return this.answers.reduce(
            (score, answer) => {
                let elem         = $(`<div>${ answer.value }</div>`);
                let correctZones = elem.find("dnd-value[correct='true']");

                return score + (correctZones.length * scoreForCorrectAnswer);
            },
            0
        );
    }
}
