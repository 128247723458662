import config from "@config";
import { joinUrl } from "./url";

export const getProjectServerStoragePath = (projectId: number, subPath: string = "", invalidateCache: boolean = false): string => {
    // @ts-ignore
    let BASE_URL = window.MVE_BASE_URL || joinUrl(config.APP_BASE_URL, `var/projects/${projectId}/`);
    
    return joinUrl(
        BASE_URL,
        subPath,
        invalidateCache ? `?t=${Date.now()}` : ""
    );
}
