import React from "react";
import { useEventListener } from "usehooks-ts";

import { useProjectPageContext } from "./share";

import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";

type Props = {
    project: Project;
    player: VideoPlayer;
}


const KeyBinds = ({ project, player }: Props) => {
    const { toggleFullscreen , togglePaused, paused, changeTimeDisplaysToMS, toggleMuted } = useProjectPageContext();

    useEventListener("keydown", async ({ code, shiftKey }: KeyboardEvent) => {
        if (document.activeElement && document.activeElement !== document.body)
            return false;

        switch (code) {
            case "Space":
                return togglePaused();

            case "ArrowLeft":
                player.setCurrentTime((shiftKey) ? -5000 : -1000 , true);
                changeTimeDisplaysToMS(player.currentTime());
                if (!paused){
                    togglePaused()
                }
                //window.projectPageForceRerender();
                return;

            case "ArrowRight":
                player.setCurrentTime((shiftKey) ? 5000 : 1000 , true);
                changeTimeDisplaysToMS(player.currentTime());
                if (!paused){
                    togglePaused()
                }
                //window.projectPageForceRerender();
                return;

            case "KeyM":
                toggleMuted();
                //window.projectPageForceRerender();
                return;

            case "KeyF":
                toggleFullscreen();
                return
        }
    });

    return <></>;
}

export default KeyBinds;
