import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import AnchorPosition, { AnchorCSSStyleType, getAnchorCssStyle } from "@enums/anchorposition";
import ClipProperty from "@models/clipproperty";

import AnchorSelector, { AnchorSelectorLayout } from "@components/anchorselector/anchorselector";


export default class Anchor extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.anchor";

    public value: AnchorPosition = AnchorPosition.LeftTop;

    
    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: AnchorPosition) {
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.execute();
    }

    public toStyle(styleType?: AnchorCSSStyleType) {
        return getAnchorCssStyle(this.value, styleType);
    }

    public getPropertyEditorComponent(layout: AnchorSelectorLayout = "full", allowCenterAlign: boolean = true) {
        return (
            <this.PropertyEditorWrapperComponent property={this} inline={true}>
                <this.PropertyEditor layout={layout} allowCenterAlign={allowCenterAlign} />    
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = ({ layout, allowCenterAlign }) => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [value]);
    
        return (
            <div>
                <AnchorSelector value={value} layout={layout} allowCenterAlign={allowCenterAlign} onSelect={setValue} />
            </div>
        );
    }
}
