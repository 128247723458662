import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { Form } from "react-bootstrap";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";


export default class Range extends ClipProperty {
    public title: string = "Range";
    public min: number = 0;
    public max: number = 100;
    public step: number = 1;

    public value: number = 20;

    
    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: number) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }
    
    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
    
        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);
    
        return (
            <div>
                <Form.Range
                    defaultValue={value}
                    min={this.min}
                    max={this.max}
                    step={this.step}
                    onInput={e => setValue(e.currentTarget.valueAsNumber)}
                />
            </div>
        );
    }
}
